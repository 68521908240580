export const EMPLOYEES_MOCK = [
  {
    id: 1,
    ownerEmail: "info@nanonets.com",
    name: "Info Nano",
    email: "shiva.bandari@nanonets.com",
  },
  {
    id: 2,
    ownerEmail: "info@nanonets.com",
    name: "Info Nano",
    email: "yash@nanonets.com",
  },
];

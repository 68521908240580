import React, { FC, ReactNode } from "react";
import classes from "./Tile.module.scss";

const Tile: FC<{
  value: string;
  label: string;
  additional?: ReactNode;
}> = ({ value, label, additional }) => {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.value}>{value}</div>
        <div className={classes.label}>{label}</div>
      </div>
      {additional && <div className={classes.additional}>{additional}</div>}
    </div>
  );
};

export default Tile;

import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { ReactNode } from "react";
import { FCC } from "../../../lib/types/common/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import classes from "./MoreMenu.module.scss";

type Props = {
  moreButton?: ReactNode;
  items: {
    onClick: () => void;
    icon?: ReactNode;
    text: string;
    disabled?: boolean;
  }[];
};

const MoreMenu: FCC<Props> = ({ items, moreButton }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <>
      {moreButton ? (
        <span onClick={onClick}>{moreButton}</span>
      ) : (
        <IconButton onClick={onClick}>
          <MoreVertIcon />
        </IconButton>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((item) => (
          <MenuItem
            className={classes.item}
            disabled={item.disabled}
            key={item.text}
            onClick={(event) => {
              item.onClick();
              handleClose(event);
            }}
          >
            {item.icon}
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MoreMenu;

import { useMutation, useQuery } from "react-query";
import { VendorDoc, VendorDocBare } from "../../types/vendorDoc";
import { VENDOR_DOCS_MOCK } from "../../constants/vendorDoc";
import { defaultMutationFn } from "../../util";

export const useVendorDocs = (teamId: string, vendorId: number) => {
  return useQuery<VendorDoc[], Error>(
    ["vendorDocs.mock", teamId, vendorId],
    () => VENDOR_DOCS_MOCK
  );
};

export const useAddVendorDoc = (teamId: string, vendorId: number) => {
  return useMutation<VendorDoc, Error, VendorDocBare[]>(defaultMutationFn);
};

export const useDeleteVendorDoc = (teamId: string, vendorId: number) => {
  return useMutation<VendorDoc, Error, number>(defaultMutationFn);
};

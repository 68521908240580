import { useAuth0 } from "../../react-auth0-spa";
import { useMutation } from "react-query";
import queryClient from "../../../lib/queryClient";
import { useNotification } from "../../../ui/utils/notification/notification2";
import { DeleteInvoiceParams } from "../../types/invoice";
import { DOC_TYPE, Invoice } from "../../../lib/types/invoice";
import _ from "lodash";

export const useDeleteInvoice = (currentTeam) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<string, Error, DeleteInvoiceParams>(
    async ({ ids, comment }) => {
      const token = await getTokenSilently();
      const deleteUrl = `/api/v1/invoices/delete/?teamid=${currentTeam}&comment=${comment}`;

      return fetch(deleteUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: ids }),
      }).then((res) =>
        res.ok
          ? Promise.resolve("Deleted")
          : Promise.reject(new Error("Error while deleting invoice(s)"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Deleted successfully");
        queryClient.invalidateQueries(["invoices", currentTeam]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
      onSettled: (data, error) => {},
    }
  );
};

export const useHardDeleteInvoice = (currentTeam) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<string, Error, DeleteInvoiceParams>(
    async ({ ids, comment }) => {
      const token = await getTokenSilently();
      const deleteUrl = `/api/v1/invoices/delete/?teamid=${currentTeam}&comment=${comment}&hard=yes`;

      return fetch(deleteUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: ids }),
      }).then((res) =>
        res.ok
          ? Promise.resolve("Deleted")
          : Promise.reject(new Error("Error while deleting invoice"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Deleted successfully");
        queryClient.invalidateQueries(["invoices", currentTeam]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUploadFile = (teamId: string) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();

  return useMutation<
    { status: string },
    Error,
    {
      uploadedFile: File;
      docType?: string;
      invoice?: Invoice;
    }
  >(
    async ({ uploadedFile, docType, invoice }) => {
      const token = await getTokenSilently();
      const url = `/api/v1/invoices/?fromui=true&teamid=${teamId}`;

      const formData = new FormData();
      formData.append("file", uploadedFile);

      if (docType) {
        formData.append("docType", docType);
      }

      if (invoice) {
        const rawData = JSON.stringify(
          _.pick(invoice, [
            "VendorId",
            "Extracted_amount",
            "Currency",
            "Extracted_doc_number",
            "Final_doc_date",
            "InlineItems",
            "Project",
          ])
        );
        formData.append("rawData", rawData);
      }

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (resp.ok) {
        return resp.json();
      }

      const errorMessage = await resp.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onError: (error) => {
        notification.error(error.message);
      },
      onSuccess: (_, { invoice, docType }) => {
        // bring the new data
        queryClient.invalidateQueries(["invoices", teamId]);
        if (invoice && docType === DOC_TYPE.PO) {
          notification.success("Purchase order created successfully");
        }
      },
    }
  );
};

import * as approver from "./queries/invoice/approver";
import * as approvals from "./queries/approvals";
import * as user from "./queries/user";
import * as team from "./queries/team/team";
import * as businessCode from "./queries/businessCode";
import * as vendorDoc from "./queries/vendor/doc";
import * as file from "./queries/cloud/file";
import * as employee from "./queries/employee/employee";
import * as stripe from "./queries/payment/stripe";
import * as vendor from "./queries/vendor";
import * as invoice from "./queries/invoice";
import * as invoice2 from "./queries/invoice/invoice";
import * as spend from "./queries/analytics/spend";
import * as category from "./queries/categories";
import * as invoiceExport from "./queries/invoice/export";
import * as gl from "./queries/gl";
import * as revolut from "./queries/payment/revolut";
import * as payments from "./queries/payments";
import * as exportQueries from "./queries/export";
import * as quickbooks from "./queries/external/quickbooks";
import * as d365 from "./queries/external/d365";
import * as admin from "./queries/admin/admin";

const queries = {
  ...approver,
  ...approvals,
  ...user,
  ...team,
  ...businessCode,
  ...vendorDoc,
  ...file,
  ...employee,
  ...stripe,
  ...vendor,
  ...invoice,
  ...invoice2,
  ...spend,
  ...category,
  ...invoiceExport,
  ...gl,
  ...revolut,
  ...payments,
  ...exportQueries,
  ...quickbooks,
  ...d365,
  ...admin,
};

export default queries;

export const SPEND_ANALYTICS_MOCK = {
  graphType: "WOW",
  dataPoints: [
    {
      amount: 350,
      date: "2022-12-01",
      currency: "$",
      count: 2,
    },
    {
      amount: 360,
      date: "2023-01-01",
      currency: "$",
      count: 2,
    },
    {
      amount: 35000,
      date: "2023-01-15",
      currency: "$",
      count: 2,
    },
    {
      amount: 4907,
      date: "2023-02-21",
      currency: "$",
      count: 2,
    },
  ],
};

import { Dialog, Button } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import classes from "./Alert.module.scss";

type AlertInfo = {
  showMessage: (message: string) => void;
};
const AlertContext = createContext<AlertInfo>({
  showMessage: () => {},
});

export const AlertProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const showMessage = (m) => {
    setMessage(m);
    setOpen(true);
  };

  const reset = () => {
    setOpen(false);
    setMessage("");
  };

  return (
    <AlertContext.Provider value={{ showMessage }}>
      {children}
      <Dialog open={open} onClose={reset}>
        <div className={classes.alert}>
        <div className={classes.message}>{message}</div>
        <Button variant="contained" onClick={reset} className={classes.ok}>
          OK
        </Button>
        </div>
      </Dialog>
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const alertInfo = useContext(AlertContext);
  return (message: string) => {
    alertInfo.showMessage(message);
  };
};

import { useMutation, useQuery } from "react-query";
import { BusinessCode } from "../types/businessCode";
import {
  defaultMutationFn,
} from "../util";

export const useBusinessCodes = (ownerEmail) => {
  return useQuery<BusinessCode[], Error>(
    ["businessCodes", ownerEmail],
    () => []
  );
};

export const useAddBusinessCode = (teamId: string) => {
  return useMutation<unknown, Error, unknown, unknown>(defaultMutationFn);
};

export const useDeleteBusinessCode = (teamId: string) => {
  return useMutation<unknown, Error, unknown, unknown>(defaultMutationFn);
};

import { useMutation, useQuery } from "react-query";
import { VENDORS_MOCK } from "../constants/vendor";
import { Vendor } from "../types/vendor";
import { defaultMutationFn } from "../util";

export const useVendor = (teamId: string, vendorId: number) => {
  return useQuery(["vendor.mock"], () => VENDORS_MOCK[0]);
};
export const useVendorById = (teamId: string, vendorId: number) => {
  return useQuery<Vendor, Error>(["vendorById.mock"], () => VENDORS_MOCK[0]);
};

export const useSendVendorEmail = (teamId: string) => {
  return useMutation<Vendor, Error, Partial<Vendor>>(defaultMutationFn);
};

export const useVendorByToken = (token: string) => {
  return useQuery(["vendorByToken.mock", token], () => VENDORS_MOCK[0]);
};

export const useVendors = (teamId: string) => {
  return useQuery(["vendors.mock", teamId], () => VENDORS_MOCK);
};

export const useAddVendor = (teamId: string) => {
  return useMutation<Vendor, Error, { vendor: Partial<Vendor> }>(
    defaultMutationFn
  );
};

export const useUpdateVendorByToken = (token: string) => {
  return useMutation<Vendor, Error, { vendor: Vendor }>(defaultMutationFn);
};

export const useUpdateVendorByEmail = () => {
  return useMutation<Vendor, Error, { vendor: Vendor }>(defaultMutationFn);
};
export const useDeleteVendor = (teamId: string) => {
  return useMutation<string[], Error, { vendors: number[] }>(defaultMutationFn);
};

import moment from "moment";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { INVOICE_LIFECYCLE_STAGE } from "../lib/constants/constants";
import { DOC_TYPE } from "../lib/types/invoice";
import { TeamUserRole } from "../api/types/team";

/**
 * @summary get the date string in the YYYY-MM-DD format
 * @param {string} date
 * @returns {string}
 */
export const getFormattedDate = (date) => {
  if (!date || !date.length) {
    return date;
  }

  const sanitizedDate = date.replaceAll(" ", "");

  try {
    let formattedDate = moment(sanitizedDate).format("YYYY-MM-DD");
    if (formattedDate === "Invalid date") {
      // one cause of the error may be that the date is not in the correct format (i.e. MM-DD-YYYY)
      // so we try to parse it as a DD-MM-YYYY format
      formattedDate = moment(sanitizedDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    }

    return formattedDate === "Invalid date" ? date : formattedDate;
  } catch (err) {
    console.error(err);
    return date;
  }
};

export const getStatus = (value) => {
  return (
    {
      Fetched: "Received",
      Approved: "Pending",
      First_Approved: "Received",
      Second_Approved: "Received",
      Rejected: "Rejected",
      Failed: "Processing",
    }[value] || value
  );
};

export const getReverseStatus = (value) => {
  return (
    {
      Received: "Fetched",
      Pending: "Approved",
      Paid: "Paid",
      Rejected: "Rejected",
    }[value] || value
  );
};

export const ValidationErrorTooltip = styled(Tooltip)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFF9F9",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 12,
    display: "flex",
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: "#FFF9F9",
    borderLeft: "2px solid #D43131",
    boxShadow: "0 0 8px 2px #0002",
    color: "#D43131",
  },
}));

export const AvatarText = styled("span")({
  color: "#fff",
  fontSize: 12,
});

export const stringToColor = (string) => {
  if (string && string.length > 0) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }
};

export const capitalizeFirstLetter = (input) => {
  return input ? input?.charAt(0)?.toUpperCase() + input?.slice(1) : input;
};

export const parseBool = (params) => {
  return !(
    params === "false" ||
    params === "0" ||
    params === "" ||
    params === undefined ||
    params === null
  );
};

export const getInvoiceDefaultFilters = (
  user: any,
  userRole: string,
  isPro: boolean,
  isDefaultApproverFilterOff: boolean
) => {
  const filters = {};

  const shouldSkipApproverFilter =
    isDefaultApproverFilterOff ||
    !isPro ||
    userRole === TeamUserRole.AP_TEAM_MEMBER ||
    userRole === TeamUserRole.AUDITOR ||
    userRole === TeamUserRole.CONTROLLER ||
    userRole === TeamUserRole.EMPLOYEE;

  if (shouldSkipApproverFilter) {
    filters["lifecycleStage"] = INVOICE_LIFECYCLE_STAGE.ACTIVE;
    filters["docType"] = DOC_TYPE.INVOICE;
  } else {
    filters["lifecycleStage"] = INVOICE_LIFECYCLE_STAGE.ACTIVE;
    filters["docType"] = DOC_TYPE.INVOICE;
    filters["approver"] = {
      values: [user],
      restrict: true,
    };
  }
  return filters;
};

export const getCurrencySymbolFromCode = (currency: string) => {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "INR":
      return "₹";
  }
};

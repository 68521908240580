import api from "../api/api";
import { useAuth0 } from "../api/react-auth0-spa";
import { useUrlSearchParams } from "./common/url";

export const useTeamId = () => {
  const { teamId } = useUrlSearchParams();
  return teamId;
};

export const useIsSuperAdmin = () => {
  const { isAuthenticated, user } = useAuth0();

  if (!isAuthenticated) return false;

  const userEmail: string = user?.email || "";

  const isNanonetsUser = userEmail.endsWith("@nanonets.com");

  // Checking only when nanonets user
  const { data: superAdmins = [] } = api.useSuperAdmins(isNanonetsUser);

  return superAdmins.includes(userEmail);
};

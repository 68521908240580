import { useMutation, useQuery } from "react-query";
import { PaymentStatus } from "../../types/payment";
import { defaultMutationFn } from "../../util";

export const useTransferPayment = (teamId: string) => {
  return useMutation<
    Record<string, PaymentStatus>,
    Error,
    { invoiceIds: string[]; referenceId: string }
  >(defaultMutationFn);
};

export const useCreatePaymentLink = (teamId: string, invoiceIds: string[]) => {
  return useQuery(["paymentLink", teamId, ...invoiceIds], () => "");
};

export const useStripeConnectLink = (teamId: string) => {
  return useQuery(["connectLink", teamId], () => ({ url: "" }));
};

export const useStripeConfirmConnection = (teamId: string) => {
  return useMutation<void, Error, { code: string }>(defaultMutationFn);
};

export const useStripeDeleteConnection = (teamId: string) => {
  return useMutation<void, Error, void>(defaultMutationFn);
};

export const useSyncStripeAccounts = (teamId: string) => {
  return useMutation<void, Error, void>(defaultMutationFn);
};

import {
  KeyboardArrowDown,
  LocalOfferSharp,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Checkbox,
  InputAdornment,
  Popover,
  TextField,
  useAutocomplete,
} from "@mui/material";
import classes from "./Filter.module.scss";
import React, { useRef, ReactNode } from "react";
import { AvatarText, stringToColor } from "../../../utils/util";
import clsx from "clsx";
import { FilterOption } from "./types";
import OverflowTip from "../OverflowTip/OverflowTip";

type AutoCompleteOption<T extends string> = {
  title: T;
  selected: boolean;
};

type Props<T extends string> = {
  filterOption: FilterOption;
  allFilterValues: T[];
  selectedFilterValues: T[];
  onFilterChange: (key: string, values: T[]) => void;
  content?: ReactNode;
};

const StringFilter = <T extends string = string>({
  filterOption,
  allFilterValues,
  selectedFilterValues,
  onFilterChange,
  content,
}: Props<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);
  const inputRef = useRef<HTMLInputElement>();

  const getDivText = (values) => {
    if (values?.length > 0) {
      const count = values?.length - 1;
      return values?.length > 1
        ? filterOption?.name + " : " + values[0] + " +" + count
        : filterOption?.name + " : " + values[0];
    } else {
      return filterOption?.name;
    }
  };

  const getOptions = (all: T[], selected: T[]) => {
    let options = [];
    all?.forEach((ele) => {
      if (ele && selected?.includes(ele)) {
        options.push({
          title: ele,
          selected: true,
        });
      } else {
        ele &&
          options.push({
            title: ele,
            selected: false,
          });
      }
    });
    return options;
  };
  const filterButtonText = getDivText(selectedFilterValues);
  const options: AutoCompleteOption<T>[] = getOptions(
    allFilterValues,
    selectedFilterValues
  );

  const { groupedOptions, getInputProps, getListboxProps } = useAutocomplete({
    id: "use-autocomplete",
    options,
    getOptionLabel: (option) => {
      return option["title"] || "";
    },
    // value: options?.filter((e) => e.selected),
    multiple: true,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleOption = (newOption: AutoCompleteOption<T>) => {
    const newOptions: AutoCompleteOption<T>[] = options.map((option) => {
      return {
        ...option,
        selected:
          newOption.title === option.title ? !option.selected : option.selected,
      };
    });
    const newValue = newOptions.filter((e) => e.selected).map((e) => e.title);
    onFilterChange(filterOption?.key, newValue);
  };

  const displayOptions =
    groupedOptions.length > 0 || inputRef.current?.value
      ? groupedOptions
      : options;

  return (
    <div className={classes.filter}>
      <Button
        onClick={handleClick}
        classes={{
          root: clsx({
            [classes.btn]: true,
            [classes.active]: selectedFilterValues.length > 0,
            [classes.open]: open,
          }),
        }}
        endIcon={<KeyboardArrowDown style={{ fontSize: "14px" }} />}
      >
        {filterButtonText}
      </Button>

      <Popover
        id={filterOption?.name}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
      >
        <div className={classes.autocomplete}>
          <div className={classes.title}>{filterOption?.name}</div>
          {content && <div className={classes.content}>{content}</div>}
          <TextField
            variant="outlined"
            inputProps={getInputProps()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search style={{ fontSize: 14 }} />
                </InputAdornment>
              ),
              style: { fontSize: 12 },
              inputRef,
            }}
            placeholder="Search"
            fullWidth
            size="small"
          />
          <ul
            {...getListboxProps()}
            style={{ maxHeight: "40vh", maxWidth: "25vw" }}
          >
            {displayOptions.map((option, index) => (
              <li
                onClick={() => toggleOption(option)}
                key={`${option["title"]}-${index}`}
              >
                <Checkbox
                  checked={option?.selected}
                  style={{
                    transform: "scale(0.7)",
                  }}
                />
                <div className={classes.avatar}>
                  {filterOption?.key === "status" ||
                  filterOption?.key === "paymentMethod" ||
                  filterOption?.key === "docNumber" ? (
                    <></>
                  ) : filterOption?.key === "gl" ||
                    filterOption?.key === "category" ? (
                    <LocalOfferSharp
                      style={{
                        color: "#adb2c280",
                        fontSize: 14,
                        transform: "rotateY(180deg)",
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        height: 20,
                        width: 20,
                        background: stringToColor(option["title"]),
                      }}
                    >
                      <AvatarText>
                        {option["title"]?.charAt(0).toUpperCase()}
                      </AvatarText>
                    </Avatar>
                  )}
                </div>
                <OverflowTip
                  className={clsx(
                    classes.name,
                    filterOption?.key === "status"
                      ? classes[option["title"]]
                      : ""
                  )}
                >
                  {option["title"]}
                </OverflowTip>
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
};

export default StringFilter;

import { Dispatch, SetStateAction, useState } from "react";

type UpdateHandler = () => void;

export const useBooleanState: (
  def?: boolean
) => [
  boolean,
  UpdateHandler,
  UpdateHandler,
  UpdateHandler,
  Dispatch<SetStateAction<boolean>>
] = (defaultVal = false) => {
  const [val, update] = useState(defaultVal);

  const set = () => update(true);
  const reset = () => update(false);
  const toggle = () => update((val) => !val);

  return [val, set, reset, toggle, update];
};

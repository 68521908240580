import { format, isValid } from "date-fns/fp";
import React, { FC } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import api from "../../../api/api";
import { useTeamId } from "../../../utils/team";
import { AnalyticsPageState } from "../state";
import classes from "./SpendsGraph.module.scss";
import { asAmountString, shortenAmount } from "./util";
import { Skeleton } from "@mui/material";

const PERIODS = [
  {
    graphType: "DOD",
    period: "Daily",
  },
  {
    graphType: "WOW",
    period: "Weekly",
  },
  {
    graphType: "MOM",
    period: "Monthly",
  },
];

const SpendsGraph: FC<{
  filters: AnalyticsPageState["filters"];
  baseCurrency: string;
  height?: number;
  showAmount?: boolean;
}> = ({ filters, baseCurrency, height = 300, showAmount = false }) => {
  const teamId = useTeamId();
  const { data, isLoading } = api.useSpendAnalytics(
    teamId,
    {
      ...filters,
      status: ["paid"],
    },
    baseCurrency
  );
  const spendAnalytics = data?.dataPoints || [];

  const formatMonth =
    data?.graphType === "MOM" ? format("MMM yyyy") : format("dd MMM yyyy");

  const additional = PERIODS.find(
    (p) => data?.graphType === p.graphType
  )?.period;

  const totalAmount = spendAnalytics.reduce(
    (total, spend) => total + spend.amount,
    0
  );
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.amount}>
          {isLoading ? (
            <Skeleton width={100} />
          ) : (
            showAmount && `${baseCurrency}${asAmountString(totalAmount)}`
          )}
        </div>
        {/* <div className={classes.selection}>
          {PERIODS.map((p) => (
            <div
              key={p.graphType}
              className={clsx(
                classes.period,
                data?.graphType === p.graphType && classes.active
              )}
            >
              {p.period}
            </div>
          ))}
        </div> */}
        {isLoading ? (
          <Skeleton variant="rounded" width={60} height={20} />
        ) : (
          additional && <div className={classes.additional}>{additional}</div>
        )}
      </div>
      {isLoading ? (
        <Skeleton variant="rounded" height={60} />
      ) : (
        <ResponsiveContainer
          height={height}
          width="100%"
          className={classes.chart}
        >
          <ComposedChart
            data={spendAnalytics.map((s) => ({
              ...s,
              x: new Date(s.date),
            }))}
            height={height}
            width={600}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" x2="0" y1="0" y2="1">
                <stop offset="5%" stopColor="#546fff" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#546fff" stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid vertical={false} strokeOpacity={0.3} />
            <XAxis
              dataKey="x"
              tickLine={false}
              axisLine={false}
              padding={{ left: 24, right: 24 }}
              tickFormatter={formatMonth}
            />
            <YAxis
              width={60}
              dataKey="amount"
              tickLine={false}
              axisLine={false}
              tickFormatter={(a) => `${baseCurrency}${shortenAmount(a)}`}
            />
            <Tooltip<number, string>
              content={({ payload, label }) => {
                return (
                  <div className={classes.tooltip}>
                    <div className={classes.date}>
                      {isValid(label) && formatMonth(label)}
                    </div>
                    {payload?.map((v) => (
                      <div key={v.name} className={classes.value}>
                        {v.name}: {asAmountString(v.value)}
                      </div>
                    ))}
                  </div>
                );
              }}
            />
            <Area
              dataKey="amount"
              fill="url(#colorUv)"
              fillOpacity={1}
              isAnimationActive
              dot={{ fill: "white" }}
              stroke="#546fff"
              activeDot={{ fill: "#546fff" }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default SpendsGraph;

import { ArrowDropDownRounded } from "@mui/icons-material";
import createTheme from "@mui/material/styles/createTheme";
import { textTransform } from "@mui/system";
import type {} from "@mui/x-data-grid/themeAugmentation";

const palette = {
  primary: {
    main: "#546fff",
  },
};

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Inter, Roboto",
    },
  },
  palette: palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  components: {
    // Style sheet name ⚛️
    MuiButton: {
      styleOverrides: {
        // Name of the rule
        root: {
          // Some CSS
          textTransform: "none",
          fontWeight: 600,
          fontSize: 12,
          padding: "4px 8px",
          lineHeight: "28px",
        },
        colorInherit: {
          borderColor: "rgba(0, 0, 0, 0.12)",
          color: "#536074",
        },
      },
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 600,
          fontSize: 28,
          color: "#1F2129",
          marginTop: 16,
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        sizeSmall: {
          width: 42,
          height: 28,
          ".MuiSwitch-thumb": {
            margin: 5,
            width: 10,
            height: 10,
          },
          ".MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(14px)",
            ".MuiSwitch-thumb": {
              backgroundColor: "white",
            },
            "+.MuiSwitch-track": {
              opacity: 1,
              backgroundColor: palette.primary.main,
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDropDownRounded,
      },
      styleOverrides: {
        outlined: {
          backgroundColor: "white",
          "& ~ fieldset": {
            top: 0,
            "& legend": {
              display: "none", // Remove the notch
            },
          },
        },
        select: {
          fontSize: 12,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 16,
          fontSize: 16,
          lineHeight: 1,
          fontWeight: 600,
          color: "#1F2129",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
          padding: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: { minHeight: 28, borderBottom: "1px solid #ecedf1" },
        flexContainer: { gap: 16 },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 12,
          padding: 4,
          minHeight: 32,
          textTransform: "capitalize",
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        disableSelectionOnClick: true,
        autoHeight: true,
      },
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        columnHeaderTitle: {
          fontWeight: 700,
          fontSize: 10,
          textTransform: "uppercase",
          color: "#7E86A0",
        },
        columnSeparator: {
          "& svg": {
            opacity: "0.4",
          },
        },
        cell: {
          "& svg": {
            fontSize: 16,
          },
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontSize: 12,
        },
        displayedRows: {
          fontSize: 12,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#1f2129",
        },
        arrow: {
          color: "#1f2129",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: 12,
        },
        noOptions: {
          fontSize: 12,
        }
      }
    }
  },
});

export default theme;

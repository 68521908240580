import {
  CheckOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import classes from "./FilterButton.module.scss";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { FC, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import {
  formatISO,
  Interval,
  isSameDay,
  parseISO,
  startOfToday,
} from "date-fns";

export const MAX_TIMESTAMP = 8640000000000000;

export const intervalToDateFilter = (interval: Interval): DateFilterObj => {
  if (!interval) return { type: "" };
  const isOriginDay = isSameDay(interval.start, 0);
  const isDoomsDay = isSameDay(interval.end, MAX_TIMESTAMP);

  if (isOriginDay && isDoomsDay) return { type: "" };

  const start = formatISO(interval.start, { representation: "date" });
  const end = formatISO(interval.end, { representation: "date" });

  if (isSameDay(interval.start, interval.end)) {
    return {
      type: "dated",
      start,
      end: start,
    };
  } else if (isOriginDay) {
    return {
      type: "datedBefore",
      start: end,
      end,
    };
  } else if (isDoomsDay) {
    return {
      type: "datedAfter",
      start,
      end: start,
    };
  } else {
    return { type: "datedBetween", start, end };
  }
};

export const dateFilterToInterval = (filter: {
  type: DateFilterType | string;
  start: string;
  end: string;
}): Interval => {
  const interval: Interval = {
    start: startOfToday(),
    end: startOfToday(),
  };

  switch (filter.type) {
    case "dated":
      interval.start = parseISO(filter.start);
      interval.end = parseISO(filter.start);
      return interval;
    case "datedBefore":
      interval.start = 0;
      interval.end = parseISO(filter.end);
      return interval;
    case "datedAfter":
      interval.start = parseISO(filter.start);
      interval.end = MAX_TIMESTAMP;
      return interval;
    case "datedBetween":
      interval.start = parseISO(filter.start);
      interval.end = parseISO(filter.end);
      return interval;
  }

  return interval;
};

type DateFilterKey = "invoiceDate" | "dueDate" | "updatedAt";
type DateFilterType =
  | "dated"
  | "datedBefore"
  | "datedAfter"
  | "datedBetween"
  | "";
type DateFilterObj = {
  start?: string;
  end?: string;
  type: DateFilterType;
};
type Props = {
  filterOption: {
    name?: string;
    key?: DateFilterKey | string;
  };
  dateFilter: DateFilterObj;
  onFilterChange: (
    key: DateFilterKey | string,
    filter: {
      type: DateFilterType | string;
      start: string;
      end: string;
    }
  ) => void;
};

const DateFilter: FC<Props> = ({
  filterOption,
  dateFilter,
  onFilterChange,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(dateFilter?.type || "dated");
  const [startDateValue, setStartDateValue] = React.useState(
    dateFilter?.start || new Date()
  );
  const [endDateValue, setEndDateValue] = React.useState(
    dateFilter?.end || new Date()
  );
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const getDivText = (filter) => {
    if (filter?.type) {
      let text = "";
      switch (filter?.type) {
        case "datedBefore":
          text = "Before " + filter?.start;
          break;
        case "datedAfter":
          text = "After " + filter?.start;
          break;
        case "datedBetween":
          text = "Between " + filter?.start + " and " + filter?.end;
          break;
        default:
          text = "" + filter?.start;
      }
      return filterOption?.name + " : " + text;
    } else {
      return "";
    }
  };
  const divText = getDivText(dateFilter);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getPrefix = (key) => {
    switch (key) {
      case "invoiceDate":
        return "Dated";
      case "dueDate":
        return "Due";
      case "updatedAt":
        return "Updated";
      default:
        return "";
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const updateFilter = () => {
    const filter = {
      type: value,
      start: moment(startDateValue).format("YYYY-MM-DD"),
      end:
        value === "datedBetween"
          ? moment(endDateValue).format("YYYY-MM-DD")
          : moment(startDateValue).format("YYYY-MM-DD"),
    };
    onFilterChange(filterOption?.key, filter);
    setAnchorEl(null);
    setOpen(false);
  };

  const open = Boolean(anchorEl);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onClick = (e) => {
    setOpen(!isOpen);
    handleClick(e);
  };
  return (
    <div className={classes.root}>
      <Button
        className={isOpen || divText ? classes.buttonOpen : classes.buttonClose}
        onClick={onClick}
        endIcon={
          isOpen ? (
            <KeyboardArrowUp style={{ fontSize: "14px" }} />
          ) : (
            <KeyboardArrowDown style={{ fontSize: "14px" }} />
          )
        }
      >
        {divText ? divText : filterOption?.name}
      </Button>

      <Popover
        id={filterOption?.name}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "16px" }}>
          <div className={classes.titleDiv}>{filterOption?.name}</div>
          <div>
            <RadioGroup
              aria-labelledby="date-filter-radio"
              defaultValue="dated"
              value={value}
              name="date-filter-radio-buttons-group"
              onChange={onChange}
            >
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                value="dated"
                label={
                  <div className={classes.label}>
                    {filterOption?.key === "invoiceDate"
                      ? getPrefix(filterOption?.key)
                      : getPrefix(filterOption?.key) + " on"}
                  </div>
                }
                style={{ marginBottom: 0 }}
              ></FormControlLabel>
              {value === "dated" && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={startDateValue}
                    onChange={(newValue) => {
                      setStartDateValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        hiddenLabel
                        variant="filled"
                        size="small"
                        className={classes.label}
                        sx={{
                          input: { fontSize: 12 },
                          svg: { fontSize: 14 },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                value="datedBefore"
                label={
                  <div className={classes.label}>
                    {getPrefix(filterOption?.key) + " before"}
                  </div>
                }
                style={{ marginBottom: 0 }}
              />
              {value === "datedBefore" && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={startDateValue}
                    onChange={(newValue) => {
                      setStartDateValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        hiddenLabel
                        size="small"
                        className={classes.label}
                        sx={{
                          input: { fontSize: 12 },
                          svg: { fontSize: 14 },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                value="datedAfter"
                label={
                  <div className={classes.label}>
                    {getPrefix(filterOption?.key) + " after"}
                  </div>
                }
                style={{ marginBottom: 0 }}
              />
              {value === "datedAfter" && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={startDateValue}
                    onChange={(newValue) => {
                      setStartDateValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        hiddenLabel
                        size="small"
                        className={classes.label}
                        sx={{
                          input: { fontSize: 12 },
                          svg: { fontSize: 14 },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                value="datedBetween"
                label={
                  <div className={classes.label}>
                    {getPrefix(filterOption?.key) + " between"}
                  </div>
                }
                style={{ marginBottom: 0 }}
              />
              {value === "datedBetween" && (
                <>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      value={startDateValue}
                      onChange={(newValue) => {
                        setStartDateValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          size="small"
                          hiddenLabel
                          className={classes.label}
                          sx={{
                            input: { fontSize: 12 },
                            svg: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <div
                    className={classes.label}
                    style={{ marginTop: 6, marginBottom: 6 }}
                  >
                    And
                  </div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      value={endDateValue}
                      onChange={(newValue) => {
                        setEndDateValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="filled"
                          hiddenLabel
                          className={classes.label}
                          sx={{
                            input: { fontSize: 12 },
                            svg: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <br />
                </>
              )}
            </RadioGroup>
            <Button
              variant="text"
              startIcon={<CheckOutlined style={{ fontSize: 14 }} />}
              className={classes.buttonClose}
              onClick={updateFilter}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default DateFilter;

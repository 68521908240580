import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { useNotification } from "../../ui/utils/notification/notification2";
import { BusinessCode } from "../types/businessCode";
import { chainError, useDelete, useGet, usePost } from "../util";

export const useBusinessCodes = (ownerEmail) => {
  const get = useGet();
  return useQuery<BusinessCode[], Error>(["businessCodes", ownerEmail], () =>
    get(`business/code/?teamid=${ownerEmail}`).catch(
      chainError("Error while fetching vendor code")
    )
  );
};

export const useAddBusinessCode = (teamId: string) => {
  const post = usePost();
  const notification = useNotification();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ request }) =>
      post(`business/code/?teamid=${teamId}`, request).catch(
        chainError("Error while adding vendor code")
      ),
    {
      onSuccess: () => {
        notification.success("Vendor code added successfully");
        queryClient.invalidateQueries(["businessCodes", teamId]);
      },
      onError: (err) => {
        notification.error(err.message);
      },
    }
  );
};

export const useDeleteBusinessCode = (teamId: string) => {
  const deleteReq = useDelete();
  const notification = useNotification();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ codeId }) =>
      deleteReq(
        `business/code/${codeId}?teamid=${teamId}`
      ).catch(chainError("Error while deleting vendor code")),
    {
      onSuccess: () => {
        notification.success("Vendor code deleted");
        queryClient.invalidateQueries(["businessCodes", teamId]);
      },
      onError: (err) => {
        notification.error(err.message);
      },
    }
  );
};

import { useMutation, useQuery } from "react-query";
import { chainError, useGet, usePost } from "../util";
import { ExportInfo } from "../types/export";
import queryClient from "../../lib/queryClient";

const useExports = (teamId) => {
  const get = useGet();
  return useQuery<ExportInfo[], Error>(["exports"], () =>
    get(`externalIntegrations/export-info?teamid=${teamId}`).catch(
      chainError("Error while fetching exports")
    )
  );
};

export const useUpdateExports = (teamId) => {
  const post = usePost();
  return useMutation<string, any, Partial<ExportInfo>>(
    (data) =>
      post(`externalIntegrations/export-setup?teamid=${teamId}`, data).catch(
        chainError("Error while adding integration")
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["exports"]);
      },
    }
  );
};

export const useQuickbooksExport = (teamId) => {
  const exportsQuery = useExports(teamId);

  const quickbooksExport = exportsQuery.data?.find(
    (e) => e.type === "quickbooks"
  );
  return { ...exportsQuery, data: quickbooksExport };
};

export const useGoogleDriveExport = (teamId) => {
  const exportsQuery = useExports(teamId);

  const gdriveExports = exportsQuery.data?.find(
    ({ type }) => type === "gdrive"
  );
  return { ...exportsQuery, data: gdriveExports };
};

export const useMicorsoftD365Export = (teamId) => {
  const exportsQuery = useExports(teamId);

  const microsoftD365Exports = exportsQuery.data?.find(
    ({ type }) => type === "microsoft_d365"
  );
  return { ...exportsQuery, data: microsoftD365Exports };
};

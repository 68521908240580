import { GL } from "../types/gl";

export const GLS_MOCK: GL[] = [
  {
    id: 1,
    name: "Grocery 0",
    tdsApplicable: "",
    owner_email: "info@nanonets.com",
    gl_code: "",
  },
  {
    id: 2,
    name: "encryptedFirstName 2",
    tdsApplicable: "",
    owner_email: "info@nanonets.com",
    gl_code: "",
  },
  {
    id: 3,
    name: "encryptedFirstName 3",
    tdsApplicable: "",
    owner_email: "info@nanonets.com",
    gl_code: "",
  },
  {
    id: 4,
    name: "asfdfdsfsd",
    tdsApplicable: "",
    owner_email: "info@nanonets.com",
    gl_code: "",
  },
  {
    id: 5,
    name: "Test GL",
    tdsApplicable: "",
    owner_email: "info@nanonets.com",
    gl_code: "",
  },
];

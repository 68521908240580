const CurrencyList = [
  "$",
  "€",
  "£",
  "₹",
  "¥",
  "元",
  "NZ $",
  "A$",
  "C$",
  "CHF",
  "HK$",
  "AED",
  "CZK",
];

export default CurrencyList;

export const CurrencyMap = {
  $: "USD",
  "€": "EUR",
  "£": "GBP",
  "₹": "INR",
  // "¥": "JPY",
  "¥": "CNY",
  "NZ $": "NZY",
  A$: "AUD",
  C$: "CAD",
  CHF: "CHF",
  HK$: "HKD",
  "₪": "ILS",
  AED: "AED",
  CZK: "CZK",
  "kr": "DKK",
};

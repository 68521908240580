import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../api/api";
import { useNotification } from "../../../ui/utils/notification/notification2";
import { useUrlSearchParams } from "../../../utils/common/url";

const CofirmPayment = () => {
  const [notice, setNotice] = useState("Processing...");
  const { status, id, teamId } = useUrlSearchParams();
  const history = useHistory();
  const notification = useNotification();

  const { mutateAsync: transferPayment, isLoading: makingTransfer } =
    api.useTransferPayment(teamId);

  useEffect(() => {
    const goToHome = () => {
      setTimeout(() => history.push("/?teamId=" + teamId), 2000);
    };
    if (status === "success") {
      transferPayment({ invoiceIds: [], referenceId: id })
        .then((data) => {
          const allIds = Object.keys(data);
          if (allIds.length === 1) {
            history.push("/details/" + allIds[0] + "?teamId=" + teamId);
          } else {
            goToHome();
          }
        })
        .catch(goToHome);
    } else if (status === "cancelled") {
      const errorMessage = "Payment failed";
      setNotice(errorMessage);
      notification.error(errorMessage);
      goToHome();
    } else {
      setNotice("Invalid url");
      goToHome();
    }
  }, [history, id, notification, status, teamId, transferPayment]);

  return <div>{notice}</div>;
};

export default CofirmPayment;

import { useMutation, useQuery } from "react-query";
import { ApprovalRule } from "../../lib/types/approval";
import { APPROVALS_MOCK } from "../constants/approvals";
import { defaultMutationFn } from "../util";

export const useApprovalRules = (ownerEmail: string) => {
  return useQuery<ApprovalRule[]>(
    ["approvalRules", ownerEmail],
    () => APPROVALS_MOCK
  );
};

export const useAddApprovalRule = (ownerEmail: string) => {
  return useMutation<any, any, { approvalRule: ApprovalRule }>(
    defaultMutationFn
  );
};

export const useUpdateApprovalRule = (ownerEmail: string, approvalRuleId: string) => {
  return useMutation<any, any, { approvalRule: ApprovalRule }>(
    defaultMutationFn
  );
};

export const useUpdateIsActive = (ownerEmail: string) => {
  return useMutation<any, any, { approvalRule: ApprovalRule }>(
    defaultMutationFn
  );
};

export const useDeleteApprovalRules = (ownerEmail: string) => {
  return useMutation<any, any, string[]>(defaultMutationFn);
};

import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./components/App/App";
import Providers from "./components/App/Providers";
import * as serviceWorker from "./serviceWorker";

const rootEl = document.getElementById('root');
if (!rootEl) throw new Error('Root container missing in index.html');

createRoot(rootEl).render(
  <Providers>
    <App />
  </Providers>
);

serviceWorker.unregister();

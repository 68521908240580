import { useMutation, useQuery } from "react-query";
import { CATEGORIES_MOCK } from "../constants/category";
import { Category } from "../types/category";
import { defaultMutationFn } from "../util";

export const useCategories = (ownerEmail) => {
  return useQuery<Category[]>(
    ["categories.mock", ownerEmail],
    () => CATEGORIES_MOCK
  );
};

export const useAddCategory = (ownerEmail: string) => {
  return useMutation<any, any, { category: Omit<Category, "id"> }>(defaultMutationFn);
};

export const useDeleteCategory = (ownerEmail) => {
  return useMutation<any, Error, { categoryId: number }>(defaultMutationFn);
};

import { InvoicesMetadata } from "../types/metadata";

export const INVOICES_METADATA_MOCK: InvoicesMetadata = {
  approver: ["abc@myteam.com"],
  buyerName: [""],
  categoryCode: [0],
  clientId: [""],
  deletedBy: ["abc@myteam.com"],
  docNumbers: ["9012578534"],
  glCode: [0, 1],
  paymentMethod: [0],
  sentBy: ["abc@myteam.com"],
};

import { useMutation } from "react-query";
import { defaultMutationFn } from "../../util";

// For downloads
export const useGenerateSignedUrl = (teamId: string) => {
  return useMutation<{ signedUrl: string }, Error, string>((key) =>
    Promise.resolve({ signedUrl: "dummy-mock-url" })
  );
};

export const useUploadToServer = (teamId: string) => {
  return useMutation<{ key: string }, Error, File>(defaultMutationFn);
};

export const useGLUploadToServer = (teamId: string) => {
  return useMutation<{ key: string }, Error, File>(defaultMutationFn);
};

export const useCOAUploadToServer = (teamId: string) => {
  return useMutation<{ key: string }, Error, File>(defaultMutationFn);
};

// Upload to cloud server using signedUrl
export const useUploadToCloud = (teamId: string) => {
  return useMutation<{ key: string; url: string }, Error, File>(() =>
    Promise.resolve({ key: "dummy-mock-key", url: "dummy-mock-url" })
  );
};

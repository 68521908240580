import React from "react";
import { FCC } from "../lib/types/common/react";
import { AlertProvider } from "./utils/alert/Alert";
import { UiConfigProvider } from "./utils/config/config";
import { FilePickerProvider } from "./utils/file/FilePicker";

const NanoUIProvider: FCC = ({ children }) => {
  return (
    <UiConfigProvider>
      <AlertProvider>
        <FilePickerProvider>{children}</FilePickerProvider>
      </AlertProvider>
    </UiConfigProvider>
  );
};

export default NanoUIProvider;

import { useQuery } from "react-query";
import { Approver } from "../../types/approver";
import { chainError, useGet } from "../../util";

export const useApprovers = (teamId: string) => {
  const get = useGet();
  return useQuery<Approver[], Error>(["approvers", teamId], () =>
    get(`getapprovers?teamid=${teamId}`)
      .then((resp) => resp.nonFinalApprovers)
      .catch(chainError("Error while fetching approvers"))
  );
};

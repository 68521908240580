import { Interval } from "date-fns";
import { DOC_TYPE } from "../../../lib/types/invoice";
import { capitalizeFirstLetter } from "../../../utils/util";
import { intervalToDateFilter } from "../../shared/Filter/DateFilter";
import { AnalyticsPageState } from "../state";

export const convertFilterInBackendForm = (
  filters: AnalyticsPageState["filters"]
) => {
  let params = "&lifecycleStage=ACTIVE";

  const allInvoicesFilter = { ...filters };
  if (filters?.docType?.length === 0) {
    allInvoicesFilter.docType = [DOC_TYPE.INVOICE, DOC_TYPE.RECEIPT];
  }

  for (const [key, filterValue] of Object.entries(allInvoicesFilter)) {
    if (key === "status") {
      const value = filterValue as string[];
      for (const val of value) {
        if (val === "paid") {
          params += `&status=Paid`;
        } else if (val === "received") {
          params += `&status=Fetched`;
        } else if (val === "approved") {
          params += "&status=Approved";
        }
      }
    } else if (
      key === "invoiceDate" ||
      key === "dueDate" ||
      key === "exportDate"
    ) {
      const interValue = filterValue as Interval;
      const value = intervalToDateFilter(interValue);

      switch (value?.type) {
        case "dated":
          params += `&start${capitalizeFirstLetter(key)}=${
            value?.start
          }&end${capitalizeFirstLetter(key)}=${value?.start}`;
          break;
        case "datedBefore":
          params += `&end${capitalizeFirstLetter(key)}=${value?.start}`;
          break;
        case "datedAfter":
          params += `&start${capitalizeFirstLetter(key)}=${value?.start}`;
          break;
        case "datedBetween":
          params += `&start${capitalizeFirstLetter(key)}=${
            value?.start
          }&end${capitalizeFirstLetter(key)}=${value?.end}`;
          break;
        default:
          break;
      }
    } else if (key === "vendor") {
      const value = filterValue as number[];
      for (const val of value) {
        params += `&vendorId=${val}`;
      }
    } else if (key === "exportStatus") {
      const value = filterValue as string[];
      for (const val of value) {
        params += `&exportStatus=${val}`;
      }
    } else if (key === "category") {
      const value = filterValue as number[];
      for (const val of value) {
        params += `&${key}=${val}`;
      }
    } else if (key === "docType") {
      const value = filterValue as DOC_TYPE[];
      for (const val of value) {
        params += `&${key}=${val}`;
      }
    }
  }
  return params;
};

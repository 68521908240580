import { useMutation } from "react-query";
import { usePost, chainError, useGet, usePut } from "../../util";

// For downloads
export const useGenerateSignedUrl = (teamId: string) => {
  const req = useGet();
  return useMutation<{ signedUrl: string }, Error, string>((key) =>
    req(`invoices/cloud/file/?teamid=${teamId}&key=${key}`).catch(
      chainError("Error while generating url")
    )
  );
};

// For uploads
const useGenerateUploadUrl = (teamId: string) => {
  const req = usePost();
  return useMutation<{ key: string; url: string }, Error, string>((fileName) =>
    req(`invoices/cloud/file/?teamid=${teamId}`, { fileName }).catch(
      chainError("Error while generating url")
    )
  );
};

export const useUploadToServer = (teamId: string) => {
  const post = usePost();
  return useMutation<{ key: string }, Error, File>((file) => {
    const formData = new FormData();
    formData.append("file", file);
    return post(`invoices/cloud/file/?teamid=${teamId}`, formData).catch(
      chainError("Error while generating url")
    );
  });
};

export const useGLUploadToServer = (teamId: string) => {
  const post = usePost();
  return useMutation<{ key: string }, Error, File>((file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("glCode", "A");
    formData.append("glName", "B");
    formData.append("glCodeIndicator", "C");
    formData.append("threshold", "90");
    return post(`glmapping/?teamid=${teamId}`, formData).catch(
      chainError("Error while uploading gl file")
    );
  });
};

export const useCOAUploadToServer = (teamId: string) => {
  const post = usePost();
  return useMutation<{ key: string }, Error, File>((file) => {
    const formData = new FormData();
    formData.append("file", file);
    return post(`coamapping/?teamid=${teamId}`, formData).catch(
      chainError("Error while uploading charts of account")
    );
  });
};

// Upload to cloud server using signedUrl
export const useUploadToCloud = (teamId: string) => {
  const { mutateAsync: generateUrl } = useGenerateUploadUrl(teamId);

  return useMutation<{ key: string; url: string }, Error, File>((file) => {
    const formData = new FormData();
    formData.append("file", file);
    return generateUrl(file.name)
      .then(({ key, url }) =>
        fetch(url, {
          method: "PUT",
          body: formData.get("file")
        }).then(() => ({ key, url }))
      )
      .catch(chainError("Error while uploading"));
  });
};

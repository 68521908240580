import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import React, { FC } from "react";
import api from "../../../api/api";
import Tile from "../../../ui/components/display/Tile/Tile";
import { useTeamId } from "../../../utils/team";
import { asAmountString } from "../SpendsGraph/util";
import { DEFAULT_STATE } from "../state";
import classes from "./CurrentSpendTile.module.scss";

const CurrentSpendTile: FC<{ baseCurrency: string }> = ({ baseCurrency }) => {
  const teamId = useTeamId();
  const { data } = api.useSpendAnalytics(
    teamId,
    {
      ...DEFAULT_STATE.filters,
      status: ["paid"],
      invoiceDate: {
        start: startOfMonth(sub(Date.now(), { days: 65 })),
        end: endOfMonth(Date.now()),
      },
    },
    baseCurrency
  );

  const spends = data?.dataPoints || [];

  const spend =
    spends.length > 0
      ? spends[spends.length - 1]
      : {
          amount: 0,
          currency: baseCurrency,
        };
  const last =
    spends.length > 1
      ? spends[spends.length - 2]
      : {
          amount: 0,
        };

  const growth =
    spend.amount === last.amount
      ? 0
      : (100 * (spend.amount - last.amount)) / last.amount;

  return (
    <Tile
      value={`${spend.currency}${asAmountString(spend.amount)}`}
      label="Total spend this month"
      additional={
        <>
          {growth > 0 ? (
            <span className={classes.warning}>
              <ArrowUpward />{" "}
              {growth === Infinity ? "" : `${Math.round(growth)}%`}
            </span>
          ) : (
            <span className={classes.success}>
              <ArrowDownward /> {Math.round(-growth)}%
            </span>
          )}{" "}
          vs last month
        </>
      }
    />
  );
};

export default CurrentSpendTile;

import { Invoice } from "../../../lib/types/invoice";
import { find } from "lodash";
import { getFormattedDate } from "../../../utils/util";
import { GridColDef, gridDateComparator } from "@mui/x-data-grid";
import {
  renderDueDateCell,
  renderTotalDueCell,
} from "../../Invoices/InvoiceTable/components";
import classes from "./AnalyticsTable.module.scss";
import { renderActionsCell, renderStatusCell } from "./components";
import clsx from "clsx";
import api from "../../../api/api";

export const useParsedRows = (invoices: Invoice[], teamId: string) => {
  const { data: vendors } = api.useVendors(teamId);
  const { data: categories } = api.useCategories(teamId);

  const getCategoryName = (id: number) => {
    return categories?.find((c) => c.id === id)?.name;
  };

  return invoices.map((invoice) => ({
    id: invoice.Id,
    invoiceDate: getFormattedDate(invoice.Final_doc_date),
    docType: invoice.DocType,
    vendorDisplayName:
      find(vendors, { id: invoice.VendorId })?.display_name ||
      "No vendor assigned",
    invoiceNumber: invoice.Extracted_doc_number,
    category: getCategoryName(invoice.CategoryId) || "Unassigned",
    dueDate: getFormattedDate(invoice.Final_due_date),
    paymentDate: getFormattedDate(invoice.PaymentDate),
    status: invoice.Status,
    currency: invoice.Currency,
    totalDue: invoice.Extracted_amount,
    data: invoice,
  }));
};

export const getColumns = (
  goToDetails: (id: string) => void
): GridColDef<any, string, string>[] => [
  {
    field: "__check__",
    hideable: false,
    sortable: false,
    filterable: false,
    width: 0,
    align: "center",
  },

  {
    field: "invoiceDate",
    headerName: "Date",
    width: 120,
    hideable: false,
    filterable: false,
    headerClassName: classes.header,
    sortComparator: gridDateComparator,
  },
  {
    field: "docType",
    headerName: "Document Type",
    minWidth: 120,
    hideable: false,
    sortable: false,
    filterable: false,
    cellClassName: classes.docType,
    headerClassName: classes.header,
  },
  {
    field: "vendorDisplayName",
    headerName: "Vendor Name",
    flex: 1,
    hideable: false,
    sortable: false,
    filterable: false,
    headerClassName: classes.header,
  },
  {
    field: "category",
    headerName: "Category",
    minWidth: 120,
    hideable: false,
    sortable: false,
    filterable: false,
    headerClassName: classes.header,
  },
  {
    field: "totalDue",
    headerName: "Total due",
    minWidth: 120,
    hideable: false,
    sortable: false,
    filterable: false,
    headerClassName: classes.header,
    renderCell: (params) => renderTotalDueCell(params),
  },
  {
    field: "status",
    headerName: "Status",
    filterable: false,
    sortable: false,
    cellClassName: (params) => clsx(classes.statusCell, params.value),
    headerClassName: classes.header,
    renderCell: renderStatusCell,
  },
  {
    field: "dueDate",
    headerName: "Due date",
    filterable: false,
    headerClassName: classes.header,
    renderCell: (params) => renderDueDateCell(params),
    sortComparator: gridDateComparator,
  },

  {
    field: "actions",
    width: 100,
    headerName: "",
    filterable: false,
    sortable: false,
    hideable: false,
    cellClassName: classes.actionsCell,
    renderCell: (params) =>
      renderActionsCell({
        params,
        goToDetails,
      }),
    headerClassName: classes.header,
  },
];

export const CATEGORIES_MOCK = [
  { id: 1, name: "Cat2", email: "info@nanonets.com", scope: "public" },
  { id: 2, name: "Cat3", email: "info@nanonets.com", scope: "public" },
  {
    id: 3,
    name: "Cat24",
    email: "info@nanonets.com",
    scope: "public",
  },
  {
    id: 4,
    name: "Cat25",
    email: "info@nanonets.com",
    scope: "public",
  },
  { id: 5, name: "Utilities", email: "", scope: "public" },
  { id: 6, name: "Rent", email: "", scope: "public" },
  { id: 7, name: "Office supplies", email: "", scope: "public" },
  { id: 8, name: "Travel", email: "", scope: "public" },
  { id: 9, name: "Marketing", email: "", scope: "public" },
  { id: 10, name: "Sales", email: "", scope: "public" },
  { id: 11, name: "Salaries", email: "", scope: "public" },
  { id: 12, name: "Employee Training", email: "", scope: "public" },
  { id: 13, name: "Insurance", email: "", scope: "public" },
  { id: 14, name: "Bank Fees", email: "", scope: "public" },
  { id: 15, name: "IT Hardware", email: "", scope: "public" },
  { id: 16, name: "Software", email: "", scope: "public" },
  { id: 17, name: "Licenses and Permits", email: "", scope: "public" },
];

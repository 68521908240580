import { useQuery } from "react-query";
import { TeamMember, TeamUserRole } from "../../types/team";

export const useTeamMembers = (teamId: string) => {
  return useQuery<TeamMember[], Error>(["teamMembers", teamId], () => []);
};

export const useAllTeam = (teamId: string) => {
  const teamQuery = useTeamMembers(teamId);
  
  const owner: TeamMember = {
    team_member: teamId,
    role: TeamUserRole.OWNER,
    status: "active",
  };
  return {
    ...teamQuery,
    data: teamQuery.data ? [owner, ...teamQuery.data] : teamQuery.data,
  };
};

export const useApproverTeamMembers = (teamId: string) => {
  const teamQuery = useAllTeam(teamId);
  return {
    ...teamQuery,
    data: teamQuery.data
      ? teamQuery.data.filter(
          (m) =>
            m.role !== TeamUserRole.EMPLOYEE && m.role !== TeamUserRole.AUDITOR
        )
      : teamQuery.data,
  };
};

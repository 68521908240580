import { useMutation, useQuery } from "react-query";
import { chainError, useGet, usePatch, usePost } from "../../util";
import { useNotification } from "../../../ui/utils/notification/notification2";
import queryClient from "../../../lib/queryClient";
import { FeatureFlagDetails } from "../../types/featureFlag";

export const useAdminFeatureFlags = () => {
  const get = useGet();
  return useQuery<FeatureFlagDetails[], Error>(["adminFeatureFlags"], () => {
    return get(`admin/featureFlags/`).catch(
      chainError("Failed to fetch feature flags")
    );
  });
};

export const useAddAdminFeatureFlag = () => {
  const notification = useNotification();
  const post = usePost();
  return useMutation<FeatureFlagDetails[], Error, Partial<FeatureFlagDetails>>(
    (featureFlag) =>
      post(`admin/featureFlags/`, featureFlag).catch(
        chainError("Error while adding category")
      ),
    {
      onSuccess: (res) => {
        notification.success("Feature Flag added successfully");
        queryClient.setQueryData(["adminFeatureFlags"], res);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateAdminFeatureFlag = () => {
  const notification = useNotification();
  const patch = usePatch();
  return useMutation<FeatureFlagDetails[], Error, Partial<FeatureFlagDetails>>(
    (featureFlag) =>
      patch(`admin/featureFlags/`, featureFlag).catch(
        chainError("Error while adding category")
      ),
    {
      onSuccess: (res) => {
        notification.success("Feature Flag updated successfully");
        queryClient.setQueryData(["adminFeatureFlags"], res);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useSuperAdmins = (enabled: boolean) => {
  const get = useGet();
  return useQuery<string[], Error>(
    ["superAdmins"],
    () => {
      return get(`admin/superAdmins/`).catch(
        chainError("Failed to fetch data")
      );
    },
    { enabled }
  );
};

import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { useNotification } from "../../ui/utils/notification/notification2";
import { chainError, useDelete, useGet, usePost, usePut } from "../util";
import { ApprovalRule } from "../../lib/types/approval";

export const useApprovalRules = (ownerEmail) => {
  const get = useGet();
  return useQuery<ApprovalRule[]>(["approvalRules", ownerEmail], () => {
    return get(`approval/rules?teamid=${ownerEmail}`).catch(
      chainError("Error while fetching approval rules")
    );
  });
};

export const useAddApprovalRule = (ownerEmail) => {
  const post = usePost();
  const notification = useNotification();
  return useMutation<any, any, { approvalRule: ApprovalRule }>(
    ({ approvalRule }) => {
      return post(`approval/rules?teamid=${ownerEmail}`, approvalRule).catch(
        chainError("Error while adding approval rule")
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Approval rule added");
        queryClient.invalidateQueries(["approvalRules", ownerEmail]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useRerunApprovalRules = (ownerEmail, invoiceId) => {
  const post = usePost();
  const notification = useNotification();
  return useMutation<any, any>(
    () => {
      return post(`approval/rules/rerun/${invoiceId}?teamid=${ownerEmail}`).catch(
        chainError("Error while running approval rules")
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["invoiceApprovers", invoiceId]);
        queryClient.invalidateQueries(["invoice", invoiceId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateApprovalRule = (ownerEmail, approvalRuleId) => {
  const put = usePut();
  const notification = useNotification();
  return useMutation<any, any, { approvalRule: ApprovalRule }>(
    ({ approvalRule }) => {
      return put(
        `approval/rules/${approvalRuleId}?teamid=${ownerEmail}`,
        approvalRule
      ).catch(chainError("Error while updating approval rule"));
    },
    {
      onSuccess: (data) => {
        notification.success("Approval rule updated");
        queryClient.invalidateQueries(["approvalRules", ownerEmail]);
        queryClient.invalidateQueries(["approvalRule", approvalRuleId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateIsActive = (ownerEmail) => {
  const put = usePut();
  const notification = useNotification();

  return useMutation<any, any, { approvalRule: ApprovalRule }>(
    ({ approvalRule }) => {
      return put(
        `approval/rules/${approvalRule.id}?teamid=${ownerEmail}`,
        approvalRule
      ).catch(chainError("Error while updating approval rule"));
    },
    {
      onSuccess: (data) => {
        notification.success("Approval rule updated");
        queryClient.invalidateQueries(["approvalRules", ownerEmail]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useDeleteApprovalRules = (ownerEmail) => {
  const req = useDelete();
  const notification = useNotification();

  return useMutation<any, any, string[]>(
    (ids) => {
      const list = ids.map((ruleId) =>
        req(`approval/rules/${ruleId}?teamid=${ownerEmail}`).catch(
          chainError("Error while deleting approval rule")
        )
      );
      return Promise.allSettled(list).then(() => Promise.all(list));
    },
    {
      onSuccess: (_, ids) => {
        notification.success(
          `Approval rule${ids.length > 0 ? "s" : ""} deleted`
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(["approvalRules", ownerEmail]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

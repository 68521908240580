import { useQuery } from "react-query";
import { APPROVERS_MOCK } from "../../constants/approver";
import { Approver } from "../../types/approver";

export const useApprovers = (teamId: string) => {
  return useQuery<Approver[], Error>(
    ["approvers.mock", teamId],
    () => APPROVERS_MOCK
  );
};

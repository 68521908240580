import { InvoiceApprover } from "./approval";
import { Vendor } from "./vendor";

export type Invoice = {
  GLId?: any;
  CategoryId?: number;
  Id: string;
  From_email: string;
  To_email: string;
  Status: string;
  Url: string;
  Md5: string;
  Date: Date | string;
  Extracted_vendor_address: string;
  Extracted_vendor_name: string;
  Extracted_vendor_email: string;
  Extracted_vendor_phone: string;
  Extracted_vendor_vat_number: string;
  Extracted_vendor_website: string;
  Extracted_doc_number: string;
  Extracted_doc_date: string;
  Final_doc_date: string;
  Extracted_due_date: string;
  Final_due_date: string;
  Extracted_amount: string;
  Extracted_currency: string;
  Extracted_buyer_name: string;
  Extracted_bank_name: string;
  Extracted_bank_account_number: string;
  Extracted_bank_payto: string;
  DownloadPath: string;
  PaymentDate: string;
  Paid_by: string | undefined | null;
  Email_sent: number;
  Email_vendor_sent: number;
  Slack_message_sent: number;
  Email: string;
  ContentType: string;
  Duplicate: number;
  VendorId: number;
  Frequency: string;
  Comments: string;
  QbFlag: "amount_mismatch" | "export_failed" | "export_success" | "";
  ApproveRejectComment: string;
  InvoiceApprovers?: InvoiceApprover[];
  Currency: string;
  IsProcessing: boolean;
  Source: "ui" | "api" | "email" | "gdrive" | "form" | string;
  InlineItems: string[] | undefined | null;
  IsArchived: boolean;
  UpdatedAt: Date | string | null;
  ArchivedAt: Date | string | null;
  LifecycleStage: string;
  SoftDeletedBy: string;
  SoftDeletedAt: Date | string | null;
  HardDeletedBy: string;
  HardDeletedAt: Date | string | null;
  NudgedByVendor: boolean;
  Class: string;
  Project: string;
  PaymentMethod: number;
  PaymentSource: string;
  Term: string;
  Memo: string;
  TaxCode: string;
  DocType: DOC_TYPE;
  CreditCardNumber: string;
  PaymentScheduledAt: string | null;
  ScheduledPaymentStatus: string;
  ClientId: string;
  FinalBuyerName: string;
  IfscCode: string;
  CGST: string;
  SGST: string;
  IGST: string;
  GstNumber: string;
  PayableAmount: number;
  GLIds?: string;
  FlowFileId: string;
  FlowFilePath: string;
  TotalTax: string;
  ShippingCost: string;
  RetryCount: number;
  DriveFileId: string;
  SourceDetails: string;
  PostingDate: Date;
  TxnCurrency: string;
  TxnAmount: number;
  IsReadonly: boolean;
  AllowForceUpdate: boolean;
  ReconciliationDone: boolean;
  IsAutoApproved: boolean;
  CustomerPoNumber: string;
  PredictedGLIds?: number[];
  CustomerAccountNumber: string;
  Location: string;
  Starred: boolean;
  CustomTVA: string;
  CustomTotalHT: string;
  CustomDepartment: string;
  MatchedTillDate: string
};

export enum DOC_TYPE {
  INVOICE = "invoice",
  RECEIPT = "receipt",
  PO = "po",
}

export type InvoiceBundle = {
  invoice: Invoice;
  vendor: Vendor;
};

export type SelectChange = {
  title: string;
  selected: boolean;
};

export type PaymentMethod = {
  id: number;
  name: string;
  owner_email?: string;
};

export enum LifecycleStage {
  ACTIVE = "ACTIVE",
  SOFT_DELETED = "SOFT_DELETED",
  PERMANENTLY_DELETED = "PERMANENTLY_DELETED",
  ARCHIVED = "ARCHIVED",
}

export type StarOption = {
  id: number;
  name: string;
};

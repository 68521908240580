import { useMutation, useQuery } from "react-query";
import { defaultMutationFn } from "../util";
import { ExportInfo } from "../types/export";

const useExports = (teamId) => {
  return useQuery<ExportInfo[], Error>(["exports"], () => null);
};

export const useUpdateExports = (teamId) => {
  return useMutation<string, any, Partial<ExportInfo>>(defaultMutationFn);
};

export const useQuickbooksExport = (teamId) => {
  const exportsQuery = useExports(teamId);

  const quickbooksExport = exportsQuery.data?.find(
    (e) => e.type === "quickbooks"
  );
  return { ...exportsQuery, data: quickbooksExport };
};

export const useGoogleDriveExport = (teamId) => {
  const exportsQuery = useExports(teamId);

  const gdriveExports = exportsQuery.data?.find((e) => e.type === "gdrive");
  return { ...exportsQuery, data: gdriveExports };
};

export const useMicorsoftD365Export = (teamId) => {
  const exportsQuery = useExports(teamId);

  const microsoftD365Exports = exportsQuery.data?.find(
    (e) => e.type === "microsoft_d365"
  );
  return { ...exportsQuery, data: microsoftD365Exports };
};

import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { GL, TDS } from "../types/gl";
import { useNotification } from "../../ui/utils/notification/notification2";
import { chainError, useDelete, useGet, usePost } from "../util";

export const useGLs = (teamId: string) => {
  const get = useGet();
  return useQuery<GL[]>(["GLs", teamId], () =>
    get(`gl/?teamid=${teamId}`)
      .then((c) => (c === null ? [] : c))
      .catch(chainError("Error while fetching integrations"))
  );
};

export const useAddGL = (teamId: string) => {
  const notification = useNotification();
  const post = usePost();
  return useMutation<GL, Error, { gl: Omit<Omit<GL, "id">, "tdsApplicable"> }>(
    ({ gl }) =>
      post(`gl/?teamid=${teamId}`, gl).catch(
        chainError("Error while updating GL Code")
      ),
    {
      onSuccess: () => {
        notification.success("gl updated");
        queryClient.invalidateQueries(["GLs", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useDeleteGL = (teamId: string) => {
  const notification = useNotification();
  const req = useDelete();
  return useMutation<{ status: string }, Error, { gl: GL }>(
    ({ gl }) =>
      req(`gl/?teamid=${teamId}`, gl).catch(
        chainError("Error while deleting GL Code")
      ),
    {
      onSuccess: () => {
        notification.success("gl deleted");
        queryClient.invalidateQueries(["GLs", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["GLs", teamId]);
      },
    }
  );
};

export const useTds = (currentTeam: string, invoiceId: string) => {
  const get = useGet();
  return useQuery<TDS[], Error>(["tds", invoiceId], () =>
    get(`tds/${invoiceId}?teamid=${currentTeam}`).catch(
      chainError("Error while getting tds details")
    )
  );
};

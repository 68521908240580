import { useMutation } from "react-query";
import queryClient from "../../../lib/queryClient";
import { VendorDoc, VendorDocBare } from "../../types/vendorDoc";
import { chainError, useDelete, usePost } from "../../util";
import { useVendorById } from "../vendor";

export const useVendorDocs = (teamId: string, vendorId: number) => {
  const vendorsQuery = useVendorById(teamId, vendorId);
  return {
    ...vendorsQuery,
    data: vendorsQuery.data?.vendorDocs,
  };
};

export const useAddVendorDoc = (teamId: string, vendorId: number) => {
  const post = usePost();
  return useMutation<VendorDoc, Error, VendorDocBare[]>(
    (data) =>
      post(`vendors/${vendorId}/docs/?teamid=${teamId}`, data).catch(
        chainError("Error while creating")
      ),
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries(["vendorById", teamId, vendorId]);
        queryClient.invalidateQueries(["vendors", teamId]);
        queryClient.invalidateQueries(["vendorByToken"]);
      },
    }
  );
};

export const useDeleteVendorDoc = (teamId: string, vendorId: number) => {
  const req = useDelete();
  return useMutation<VendorDoc, Error, number>(
    (id: number) =>
      req(`vendors/${vendorId}/docs/${id}/?teamid=${teamId}`).catch(
        chainError("Error while deleting")
      ),
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries(["vendorById", teamId, vendorId]);
        queryClient.invalidateQueries(["vendors", teamId]);
        queryClient.invalidateQueries(["vendorByToken"]);
      },
    }
  );
};

import { useQuery } from "react-query";
import { convertFilterInBackendForm } from "../../../components/AnalyticsPage/AnalyticsTable/util";
import { AnalyticsPageState } from "../../../components/AnalyticsPage/state";
import { SpendAnalytics } from "../../types/analytics";
import { chainError, useGet } from "../../util";

export const useSpendAnalytics = (
  teamId: string,
  filters: AnalyticsPageState["filters"],
  baseCurrency = "$"
) => {
  const get = useGet();
  const params = convertFilterInBackendForm(filters);

  return useQuery<SpendAnalytics, Error>(
    ["spends", teamId, params, baseCurrency],
    () =>
      get(
        `analytics/spends/?teamid=${teamId}${params}${
          baseCurrency ? `&basecurrency=${baseCurrency}` : ""
        }`
      ).catch(chainError("Error while fetching analytics"))
  );
};

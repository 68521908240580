import { useMutation, useQuery } from "react-query";
import queryClient from "../../../lib/queryClient";
import { useNotification } from "../../../ui/utils/notification/notification2";
import { useAuth0 } from "../../react-auth0-spa";
import {
  Exchange,
  RevolutAccount,
  RevolutMetadata,
  RevolutRecipient,
} from "../../types/revolut";
import { useGet, usePatch, useDelete, usePost, chainError } from "../../util";

const PAYMENT_BASE_URL = `payments`;
const REVOLUT_PAYMENT_PROVIDER = "revolut";

export const useRevolutMetadata = (ownerEmail: string) => {
  const get = useGet();
  return useQuery<RevolutMetadata>(["revolutMetadata", ownerEmail], () => {
    return get(
      `${PAYMENT_BASE_URL}/metadata/${REVOLUT_PAYMENT_PROVIDER}?teamid=${ownerEmail}`
    ).catch(chainError("Error while fetching revolut metadata"));
  });
};

export const useRevolutRecipients = (ownerEmail: string) => {
  const get = useGet();
  return useQuery<RevolutRecipient[]>(["revolutRecipients", ownerEmail], () => {
    return get(
      `${PAYMENT_BASE_URL}/recipient/${REVOLUT_PAYMENT_PROVIDER}?teamid=${ownerEmail}`
    ).catch(chainError("Error while fetching revolut recipients"));
  });
};

export const useRevolutAccounts = (ownerEmail: string) => {
  const get = useGet();
  return useQuery<RevolutAccount[]>(["revolutAccounts", ownerEmail], () => {
    return get(
      `${PAYMENT_BASE_URL}/accounts/${REVOLUT_PAYMENT_PROVIDER}?teamid=${ownerEmail}`
    ).catch(chainError("Error while fetching revolut accounts"));
  });
};

export const useConvertAmount = (ownerEmail: string) => {
  const post = usePost();
  return useMutation<Exchange, Error, unknown, unknown>(({ request }) =>
    post(
      `${PAYMENT_BASE_URL}/amount/convert/${REVOLUT_PAYMENT_PROVIDER}?teamid=${ownerEmail}`,
      request
    )
  );
};

export const useRevolutPay = (ownerEmail: string) => {
  const post = usePost();
  const notification = useNotification();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ request }) => {
      return post(
        `${PAYMENT_BASE_URL}/transfer/${REVOLUT_PAYMENT_PROVIDER}?teamid=${ownerEmail}`,
        request
      ).catch(chainError("Error while paying invoice"));
    },
    {
      onSuccess: (data) => {
        notification.success("Paid successfully");
        queryClient.invalidateQueries(["invoices", ownerEmail]);
        queryClient.invalidateQueries(["revolutAccounts", ownerEmail]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (error) => {
        notification.error(error.message);
      }
    }
  );
};

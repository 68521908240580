import { useMutation, useQuery } from "react-query";
import { defaultMutationFn } from "../../util";
import { FeatureFlagDetails } from "../../types/featureFlag";

export const useAdminFeatureFlags = () => {
  return useQuery<FeatureFlagDetails[], Error>(
    ["adminFeatureFlags.mock"],
    () => []
  );
};

export const useAddAdminFeatureFlag = () => {
  return useMutation<FeatureFlagDetails[], Error, Partial<FeatureFlagDetails>>(
    defaultMutationFn
  );
};

export const useUpdateAdminFeatureFlag = () => {
  return useMutation<FeatureFlagDetails[], Error, Partial<FeatureFlagDetails>>(
    defaultMutationFn
  );
};

export const useSuperAdmins = (enabled: boolean) => {
  return useQuery<string[], Error>(["superAdmins.mock"], () => []);
};

import React, { ChangeEventHandler, FC, useState } from "react";
import { Delete, ExpandMore, ExpandLess, Download } from "@mui/icons-material";
import { Dialog, Button, Checkbox, TextField, Divider } from "@mui/material";
import { useBooleanState } from "../../../utils/common/state";
import classes from "./Delete.module.scss";
import clsx from "clsx";

type ACTION_TYPE = "delete" | "download";

type DeleteItem = {
  id: string;
  name: string;
  details: string;
  status: string;
  info?: string;
  data1?: string;
  data2?: string;
};

type Props = {
  title: string;
  showComment?: boolean;
  showConfirmation?: boolean;
  deleteItems: DeleteItem[];
  isDeleting: boolean;
  onClose: () => void;
  onDelete: (comment: string) => void;
  actionType?: ACTION_TYPE;
};

const DeleteGeneric: FC<Props> = ({
  title,
  showComment = false,
  showConfirmation = false,
  deleteItems,
  isDeleting,
  onClose,
  onDelete,
  actionType = "delete",
}) => {
  const [isConfirmed, , , toggleConfirm] = useBooleanState(!showConfirmation);
  const [isItemsExpanded, , , toggleExpandItems] = useBooleanState();
  const [comment, setComment] = useState("");

  const isMultiple = deleteItems?.length > 1;

  const onCommentUpdate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setComment(e.target.value);
  };

  return (
    <Dialog open onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.title}>{title}</div>

        <div className={classes.invoiceList}>
          {isMultiple && (
            <div className={classes.header} onClick={toggleExpandItems}>
              <div className={classes.headerText}>
                {deleteItems.length} items will be{" "}
                {actionType === "download" ? "downloaded" : "deleted"}
              </div>
              {isItemsExpanded ? <ExpandLess /> : <ExpandMore />}
            </div>
          )}
          {isItemsExpanded && <Divider />}

          {(isItemsExpanded || !isMultiple) &&
            deleteItems.map((item) => (
              <div className={classes.invoice} key={item.id}>
                <div className={classes.invoiceHeader}>
                  <div className={classes.vendor}>{item.name}</div>

                  <div className={clsx(classes.status, item.status)}>
                    <div>{item.status}</div>
                  </div>
                </div>
                <div className={classes.invoiceDetails}>
                  <div>
                    <div className={classes.info}>{item.details}</div>

                    {item.info && (
                      <div className={classes.info}>{item.info}</div>
                    )}
                  </div>
                  <div>
                    {item.data1 && (
                      <div className={classes.amount}>{item.data1}</div>
                    )}
                    {item.data2 && (
                      <div className={classes.info} style={{ float: "right" }}>
                        {item.data2}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        {showComment && (
          <TextField
            size="small"
            variant="outlined"
            value={comment}
            onChange={onCommentUpdate}
            InputProps={{ style: { fontSize: 12 } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            fullWidth
            autoFocus
            placeholder="Comment (optional)"
            className={classes.comment}
          />
        )}

        {showConfirmation && (
          <div className={classes.confirmation}>
            <Checkbox
              size="small"
              value={isConfirmed}
              onChange={toggleConfirm}
            />
            <div>I understand that this action can't be undone</div>
          </div>
        )}

        <div className={classes.footer}>
          <Button
            disabled={isDeleting || !isConfirmed}
            variant="contained"
            startIcon={actionType === "download" ? <Download /> : <Delete />}
            onClick={() => onDelete(comment)}
            disableElevation
            color={actionType === "download" ? "primary" : "error"}
            size="small"
          >
            {actionType === "download" ? "Download" : "Delete"}
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteGeneric;

import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { useNotification } from "../../ui/utils/notification/notification2";
import { Category } from "../types/category";
import { chainError, useDelete, useGet, usePost } from "../util";

export const useCategories = (teamId: string) => {
  const get = useGet();
  return useQuery<Category[]>(["categories", teamId], () =>
    get(`category/?teamid=${teamId}`)
      .then((c) => (c === null ? [] : c))
      .catch(chainError("Error while fetching categories"))
  );
};

export const useAddCategory = (teamId: string) => {
  const notification = useNotification();
  const post = usePost();
  return useMutation<Category, Error, { category: Omit<Category, "id"> }>(
    ({ category }) =>
      post(`category/?teamid=${teamId}`, category).catch(
        chainError("Error while adding category")
      ),
    {
      onSuccess: () => {
        notification.success("Category added successfully");
        queryClient.invalidateQueries(["categories", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useDeleteCategory = (teamId: string) => {
  const notification = useNotification();
  const req = useDelete();

  return useMutation<{ status: string }, Error, { categoryId: number }>(
    ({ categoryId }) =>
      req(`category/${categoryId}?teamid=${teamId}`).catch(
        chainError("Error while deleting category")
      ),
    {
      onSuccess: () => {
        notification.success("Category deleted successfully");
        queryClient.invalidateQueries(["categories", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["categories", teamId]);
      },
    }
  );
};

import { MoreHoriz, OpenInNew } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { MouseEventHandler } from "react";
import classes from "./AnalyticsTable.module.scss";
import tooltipClasses from "../../../lib/styles/customizations/Tooltip.module.scss";

export const renderActionsCell = ({ params, goToDetails }) => {
  const onDownloadClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    goToDetails(params?.row?.id);
  };
  return (
    <>
      <IconButton className={classes.more} size="small">
        <MoreHoriz />
      </IconButton>
      <IconButton
        disableRipple
        className={classes.action}
        size="small"
        onClick={onDownloadClick}
      >
        <Tooltip
          title="Open Details Page"
          placement="top"
          classes={tooltipClasses}
        >
          <OpenInNew />
        </Tooltip>
      </IconButton>
    </>
  );
};

export const renderStatusCell = (params) => {
  return params?.value === "Paid" ? "Paid" : "Unpaid";
};

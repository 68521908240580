import { endOfMonth, startOfMonth, sub } from "date-fns";
import React, { FC } from "react";
import api from "../../../api/api";
import Tile from "../../../ui/components/display/Tile/Tile";
import { useTeamId } from "../../../utils/team";
import { asAmountString } from "../SpendsGraph/util";
import { DEFAULT_STATE } from "../state";

const CurrentDueTile: FC<{ baseCurrency: string }> = ({ baseCurrency }) => {
  const teamId = useTeamId();
  const { data } = api.useSpendAnalytics(
    teamId,
    {
      ...DEFAULT_STATE.filters,
      status: ["unpaid"],
      invoiceDate: {
        start: startOfMonth(sub(Date.now(), { days: 65 })),
        end: endOfMonth(Date.now()),
      },
    },
    baseCurrency
  );
  const spends = data?.dataPoints || [];

  const spend =
    spends.length > 0
      ? spends[spends.length - 1]
      : {
          amount: 0,
          currency: baseCurrency,
          count: 0,
        };

  return (
    <Tile
      value={`${spend.currency}${asAmountString(spend.amount)}`}
      label="Payment due this month"
      additional={`${spend.count} Unpaid document(s)`}
    />
  );
};

export default CurrentDueTile;

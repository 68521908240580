import { useQuery } from "react-query";
import { AnalyticsPageState } from "../../../components/AnalyticsPage/state";
import { SPEND_ANALYTICS_MOCK } from "../../constants/analytics";
import { SpendAnalytics } from "../../types/analytics";

export const useSpendAnalytics = (
  teamId: string,
  filters: AnalyticsPageState["filters"],
  currency?: string
) => {
  return useQuery<SpendAnalytics, Error>(
    ["spends.mock", teamId, filters, currency],
    () => SPEND_ANALYTICS_MOCK
  );
};

import { useMutation, useQuery } from "react-query";
import { AccountPlan, UserDetails } from "../types/user";
import { defaultMutationFn } from "../util";
import { TeamUserRole } from "../types/team";
import { FeatureFlags } from "../types/featureFlag";

export const useTeam = (currentTeam: string) => {
  return useQuery<UserDetails, Error>(
    ["teamdetails", currentTeam],
    () => ({} as any)
  );
};

export const useFeatureFlags = (teamId: string) => {
  return useQuery<FeatureFlags, Error>(
    ["featureflags", teamId],
    () => ({} as any)
  );
};

export const useIsPro = (teamId: string) => {
  const userQuery = useTeam(teamId);
  const isPro = userQuery.data?.plan !== AccountPlan.PLAN_FREE;
  return {
    ...userQuery,
    data: isPro,
    isPro,
  };
};

export const useUserRole = (teamId: string) => {
  return useQuery<TeamUserRole, Error>(["userRole", teamId], () => ({} as any));
};

export const useStripeKey = (teamId: string) => {
  return useQuery<any, Error>(["stripeKey", teamId], () => ({} as any));
};

export const useStripeBalance = (teamId) => {
  return useQuery<any, Error>(["stripeBalance", teamId], () => ({} as any));
};

export const useUpdateUserVendorStatus = () => {
  return useMutation<any, Error, { status: string }>(defaultMutationFn);
};

export const useUpdateTeamMetadata = (teamId) => {
  return useMutation<any, Error, any>(defaultMutationFn);
};

export const useUpdateUserForwardedEmail = (teamId) => {
  return useMutation<any, Error, { forwardedEmail: string }>(defaultMutationFn);
};

export const useUpdateStripeKey = (teamId) => {
  return useMutation<any, Error, { stripeKey: string }>(defaultMutationFn);
};

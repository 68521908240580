import React, { FC, ReactNode } from "react";
import { Close } from "@mui/icons-material";
import { Button, Dialog, Divider, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import classes from "./FilePickerView.module.scss";

type Props = {
  options: {
    title: string;
    message: string;
    content?: ReactNode;
  };
  onUpload: (files: File[]) => void;
  isDialogOpen: boolean;
  closeDialog: () => void;
  showWarning: (v: string) => void;
};

const FilePickerView: FC<Props> = ({
  options,
  onUpload,
  isDialogOpen,
  closeDialog,
  showWarning,
}) => {
  const onFileDrop = (acceptedFiles: File[]) => {
    const filteredFiles = acceptedFiles.filter((file) => {
      const MB = 1000000;
      const limit = 20;

      if (file.size > limit * MB) {
        // not allowing images greater than file size limit
        showWarning(`Cannot upload files with size more than ${limit} MB`);
        return false;
      }
      return true;
    });

    if (filteredFiles.length === 0) return;

    onUpload(filteredFiles);
  };
  const { open, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [], "application/pdf": [], "text/csv": [], "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], "application/vnd.ms-excel" : [], "image/heic": [] },
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: onFileDrop,
  });

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      fullWidth
      className={classes.root}
    >
      <div className={classes.dialogTitle}>{options?.title}</div>
      <IconButton
        size="medium"
        onClick={closeDialog}
        style={{ position: "absolute", top: 6, right: 6 }}
      >
        <Close style={{ fontSize: 16 }} />
      </IconButton>
      <div
        {...getRootProps({ className: "dropzone" })}
        className={classes.fileDrop}
      >
        <input {...getInputProps()} />
        <div className={classes.textDiv}>{options?.message}</div>
        <Divider className={classes.divider}>OR</Divider>
        <Button
          variant="contained"
          className={classes.selectButton}
          onClick={open}
        >
          Upload from your device
        </Button>
        {options?.content}
      </div>
    </Dialog>
  );
};

export default FilePickerView;

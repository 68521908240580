import { isValid, parseISO } from "date-fns";
import { formatISOWithOptions } from "date-fns/fp";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DOC_TYPE } from "../../lib/types/invoice";
import { MAX_TIMESTAMP } from "../shared/Filter/DateFilter";
import { AnalyticsPageState, DEFAULT_STATE } from "./state";

const formatISODate = formatISOWithOptions({ representation: "date" });

enum AnalyticsPageParamType {
  PAGE = "page",
  ROWS_PER_PAGE = "rowsPerPage",
  CATEGORY = "category",
  DOC_TYPE = "docType",
  VENDOR = "vendor",
  STATUS = "status",
  EXPORT_STATUS = "exportStatus",
  INVOICE_DATE_FROM = "from",
  INVOICE_DATE_TO = "to",
  DUE_DATE_FROM = "due_from",
  DUE_DATE_TO = "due_to",
  EXPORT_DATE_FROM = "export_from",
  EXPORT_DATE_TO = "export_to",
  TEAM_ID = "teamId",
}

export const extractQueryParams = (search: string): AnalyticsPageState => {
  const q = new URLSearchParams(search);
  const teamId = q.get(AnalyticsPageParamType.TEAM_ID) || DEFAULT_STATE.teamId;
  const page =
    parseInt(q.get(AnalyticsPageParamType.PAGE)) || DEFAULT_STATE.page;
  const defaultRowsPerPage =
    teamId === "anand@novafarms.com" ? 100 : DEFAULT_STATE.rowsPerPage;
  const rowsPerPage =
    parseInt(q.get(AnalyticsPageParamType.ROWS_PER_PAGE)) || defaultRowsPerPage;
  const category =
    q.getAll(AnalyticsPageParamType.CATEGORY).map(parseInt) ||
    DEFAULT_STATE.filters.category;
  const docType =
    q.getAll(AnalyticsPageParamType.DOC_TYPE).map((d) => d as DOC_TYPE) ||
    DEFAULT_STATE.filters.docType;
  const vendor =
    q.getAll(AnalyticsPageParamType.VENDOR).map(parseInt) ||
    DEFAULT_STATE.filters.vendor;
  const status =
    q.getAll(AnalyticsPageParamType.STATUS) || DEFAULT_STATE.filters.status;
  const exportStatus =
    q.getAll(AnalyticsPageParamType.EXPORT_STATUS) ||
    DEFAULT_STATE.filters.exportStatus;

  const invoiceDate: Interval = { ...DEFAULT_STATE.filters.invoiceDate };
  const start = parseISO(q.get(AnalyticsPageParamType.INVOICE_DATE_FROM));
  const end = parseISO(q.get(AnalyticsPageParamType.INVOICE_DATE_TO));

  if (isValid(start)) invoiceDate.start = start;
  if (isValid(end)) invoiceDate.end = end;

  const dueDate: Interval = { ...DEFAULT_STATE.filters.dueDate };
  const dueStart = parseISO(q.get(AnalyticsPageParamType.DUE_DATE_FROM));
  const dueEnd = parseISO(q.get(AnalyticsPageParamType.DUE_DATE_TO));

  if (isValid(dueStart)) dueDate.start = dueStart;
  if (isValid(dueEnd)) dueDate.end = dueEnd;

  const exportDate: Interval = { ...DEFAULT_STATE.filters.exportDate };
  const exportStart = parseISO(q.get(AnalyticsPageParamType.EXPORT_DATE_FROM));
  const exportEnd = parseISO(q.get(AnalyticsPageParamType.EXPORT_DATE_TO));

  if (isValid(exportStart)) exportDate.start = exportStart;
  if (isValid(exportEnd)) exportDate.end = exportEnd;

  return {
    page,
    rowsPerPage,
    filters: {
      category,
      docType,
      status,
      exportStatus,
      vendor,
      invoiceDate,
      exportDate,
      dueDate,
    },
    teamId,
  };
};

export const createURLSearchParams = (
  state: AnalyticsPageState
): URLSearchParams => {
  const q = new URLSearchParams();

  if (state.teamId) {
    q.set(AnalyticsPageParamType.TEAM_ID, state.teamId);
  }

  if (state.page && state.page !== DEFAULT_STATE.page) {
    q.set(AnalyticsPageParamType.PAGE, state.page.toString());
  }
  if (state.rowsPerPage && state.rowsPerPage !== DEFAULT_STATE.rowsPerPage) {
    q.set(AnalyticsPageParamType.ROWS_PER_PAGE, state.rowsPerPage.toString());
  }
  if (state.filters.category.length > 0) {
    state.filters.category.forEach((c) => {
      q.append(AnalyticsPageParamType.CATEGORY, c.toString());
    });
  }

  if (state.filters.docType.length > 0) {
    state.filters.docType.forEach((c) => {
      q.append(AnalyticsPageParamType.DOC_TYPE, c.toString());
    });
  }

  if (state.filters.status.length > 0) {
    state.filters.status.forEach((c) => {
      q.append(AnalyticsPageParamType.STATUS, c.toString());
    });
  }
  if (state.filters.vendor.length > 0) {
    state.filters.vendor.forEach((c) => {
      q.append(AnalyticsPageParamType.VENDOR, c.toString());
    });
  }
  if (state.filters.exportStatus.length > 0) {
    state.filters.exportStatus.forEach((c) => {
      q.append(AnalyticsPageParamType.EXPORT_STATUS, c.toString());
    });
  }
  if (state.filters.invoiceDate) {
    const { start, end } = state.filters.invoiceDate;
    if (start !== 0) {
      q.set(AnalyticsPageParamType.INVOICE_DATE_FROM, formatISODate(start));
    }
    if (end !== MAX_TIMESTAMP) {
      q.set(AnalyticsPageParamType.INVOICE_DATE_TO, formatISODate(end));
    }
  }
  if (state.filters.dueDate) {
    const { start, end } = state.filters.dueDate;
    if (start !== 0) {
      q.set(AnalyticsPageParamType.DUE_DATE_FROM, formatISODate(start));
    }
    if (end !== MAX_TIMESTAMP) {
      q.set(AnalyticsPageParamType.DUE_DATE_TO, formatISODate(end));
    }
  }
  if (state.filters.exportDate) {
    const { start, end } = state.filters.exportDate;
    if (start !== 0) {
      q.set(AnalyticsPageParamType.EXPORT_DATE_FROM, formatISODate(start));
    }
    if (end !== MAX_TIMESTAMP) {
      q.set(AnalyticsPageParamType.EXPORT_DATE_TO, formatISODate(end));
    }
  }

  return q;
};

export const useAnalyticsPageUrlEffect = (state) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const q = createURLSearchParams(state);

    history.replace({
      pathname: location.pathname,
      search: "?" + q.toString(),
    });
  }, [history, location.pathname, state]);
};

import { useQuery } from "react-query";
import { chainError, useGet } from "../../util";

const dimensionsToStrings = (
  dimensions: {
    code: string;
    dimensionid: string;
    displayName: string;
    id: string;
  }[]
) => dimensions?.map((d) => d.code || "")?.sort() || [];

export const useD365Sites = (teamId: string) => {
  const get = useGet();
  return useQuery<string[], Error>(["d365Sites", teamId], () => {
    return get(`invoices/d365/sites?teamid=${teamId}`)
      .then(dimensionsToStrings)
      .catch(chainError("Failed to fetch sites"));
  });
};

export const useD365Departments = (teamId: string) => {
  const get = useGet();
  return useQuery<string[], Error>(["d365Departments", teamId], () => {
    return get(`invoices/d365/departments?teamid=${teamId}`)
      .then(dimensionsToStrings)
      .catch(chainError("Failed to fetch departments"));
  });
};

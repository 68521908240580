import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../api/api";
import { useUrlSearchParams } from "../../../utils/common/url";

const OAuthCallback = () => {
  const [notice, setNotice] = useState("Processing...");
  const { code, state } = useUrlSearchParams();
  const history = useHistory();
  const { mutateAsync: confirmConnection } =
    api.useStripeConfirmConnection(state);

  useEffect(() => {
    const goToSettings = () => {
      setTimeout(() => history.push("/settings/?teamId=" + state), 2000);
    };
    if (code) {
      confirmConnection({ code })
        .then(() => {
          setNotice("Redirecting...");
        })
        .catch(() => {
          setNotice("Invalid URL");
        })
        .finally(goToSettings);
    }
  }, [code, confirmConnection, history, state]);

  return <div>{notice}</div>;
};

export default OAuthCallback;

import React from "react";
import { Switch, Route } from "react-router-dom";
import CofirmPayment from "../Payments/StripePayment/ConfirmPayment";
import OAuthCallback from "../Settings/Stripe/OAuthCallback";

/*
  Parse get request and forward! Intended usecases:
  - Payment confirmation
  - Connect with external integrations (e.g. quickbooks, google drive, slack)
  - Vendor onboarding
  - Forgot password etc
*/

const Connects = () => {
  return (
    <Switch>
      <Route path="/connect/stripe/confirm-payment">
        <CofirmPayment />
      </Route>
      <Route path="/connect/stripe/oauth-callback">
        <OAuthCallback />
      </Route>
    </Switch>
  );
};
export default Connects;

import { DataGrid } from "@mui/x-data-grid";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../api/api";
import {
  CurrencySymbolToCode,
  INVOICE_STATUS,
} from "../../../lib/constants/constants";
import { SkeletonLoadingOverlay } from "../../../ui/components/DataGrid";
import { useTeamId } from "../../../utils/team";
import { capitalizeFirstLetter } from "../../../utils/util";
import DeleteGeneric from "../../shared/Delete/DeleteGeneric";
import { AnalyticsPageState } from "../state";
import classes from "./AnalyticsTable.module.scss";
import { getColumns, useParsedRows } from "./grid";
import { convertFilterInBackendForm } from "./util";

const AnalyticsTable: FC<{
  filters: AnalyticsPageState["filters"];
  isDownloaderOpen: boolean;
  closeDownloader: () => void;
}> = ({ filters, isDownloaderOpen, closeDownloader }) => {
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const history = useHistory();
  const teamId = useTeamId();
  const params = convertFilterInBackendForm(filters);
  const { data: invoiceList, isLoading } = api.useInvoices(teamId, params);
  const { mutateAsync: downloadExcel } = api.useDownloadExcel(teamId);

  const goToDetails = (id: string) => {
    history.replace({
      pathname: `/details/${id}`,
      search: `?teamId=${teamId}`,
    });
  };

  const rows = useParsedRows(invoiceList?.invoices || [], teamId);
  const columns = getColumns(goToDetails);

  const downloadNow = () => {
    const ids =
      selectedInvoices.length > 0 ? selectedInvoices : rows.map((r) => r.id);
    downloadExcel({ ids }).then(() => {
      closeDownloader();
    });
  };

  return (
    <div className={classes.root}>
      <DataGrid
        loading={isLoading}
        className={classes.grid}
        rows={rows}
        columns={columns}
        getRowClassName={() => classes.row}
        getCellClassName={() => classes.cell}
        page={page}
        onPageChange={setPage}
        pageSize={rowsPerPage}
        onPageSizeChange={setRowsPerPage}
        rowsPerPageOptions={[5, 10, 50, 100]}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectedInvoices}
        onSelectionModelChange={setSelectedInvoices}
        components={{
          NoRowsOverlay: () => (
            <div className="col">
              <div className="row mt-5">
                <div className="col text-center">
                  <img src="/empty_image.svg" alt=""></img>
                </div>
              </div>
            </div>
          ),
          LoadingOverlay: SkeletonLoadingOverlay,
        }}
      />
      {isDownloaderOpen && (
        <DeleteGeneric
          title={`Download ${
            selectedInvoices.length > 0 ? "selected" : "all"
          } invoices`}
          deleteItems={selectedInvoices.map((invoiceId) => {
            const invoiceRow = rows.find((r) => r.id === invoiceId);
            return {
              id: invoiceRow.id,
              name: invoiceRow.vendorDisplayName,
              status:
                invoiceRow.status === INVOICE_STATUS.PAID
                  ? INVOICE_STATUS.PAID
                  : "Unpaid",
              details: capitalizeFirstLetter(invoiceRow.docType),
              info: invoiceRow.invoiceDate,
              data1: `${invoiceRow.currency}${invoiceRow.totalDue}`,
              data2: CurrencySymbolToCode[invoiceRow.currency] || "",
            };
          })}
          isDeleting={false}
          onClose={closeDownloader}
          onDelete={downloadNow}
          actionType="download"
        />
      )}
    </div>
  );
};

export default AnalyticsTable;

export enum ACCESS_FEATURE {
  INTEGRATIONS_PAGE = "pages.integrations",
  CONVERT_TO_PRO = "actions.convert_to_pro",
  TEAM_SELECTION = "actions.select_team",
  TEAM_ADDITION = "actions.add_team",
  PAYMENT = "actions.pay",
  CONTACTS_LINK = "link.contacts",
  EMAIL_VENDOR = "actions.email_vendor",
}

export enum DYNAMIC_ACCESS_FEATURE {
  UPLOAD_INVOICES = "actions.upload", // invoices_count
}

import React, { FC, ReactNode } from "react";
import classes from "./Header.module.scss";

type Props = {
  title: string;
  desc?: string;
  actions?: ReactNode;
};

const Header: FC<Props> = ({ title, desc, actions }) => {
  return (
    <div className={classes.header}>
      <div className={classes.title}>{title}</div>
      {desc && <div className={classes.desc}>{desc}</div>}
      {actions && <div className={classes.actions}>{actions}</div>}
    </div>
  );
};

export default Header;

import { useMutation } from "react-query";
import { DeleteInvoiceParams } from "../../types/invoice";
import { defaultMutationFn } from "../../util";
import { Invoice } from "../../../lib/types/invoice";

export const useDeleteInvoice = (teamId: string) => {
  return useMutation<string, Error, DeleteInvoiceParams>(defaultMutationFn);
};

export const useHardDeleteInvoice = (teamId: string) => {
  return useMutation<string, Error, DeleteInvoiceParams>(defaultMutationFn);
};

export const useUploadFile = (teamId: string) => {
  return useMutation<
    { status: string },
    Error,
    { uploadedFile: File; docType?: string; invoice?: Invoice }
  >(defaultMutationFn);
};

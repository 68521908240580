import { ACCESS_FEATURE } from "../../lib/types/access";
import { useTeamId } from "../team";
import api from "../../api/api";

/**
 * If feature flgs are enabled in future,
 * extend the same function to enable/disable frontend features
 */
const getAccess = (feature: ACCESS_FEATURE, isPro: boolean) => {
  switch (feature) {
    case ACCESS_FEATURE.INTEGRATIONS_PAGE:
    case ACCESS_FEATURE.TEAM_SELECTION:
    case ACCESS_FEATURE.TEAM_ADDITION:
    case ACCESS_FEATURE.EMAIL_VENDOR:
      return true;
    case ACCESS_FEATURE.CONTACTS_LINK:
      return isPro;
    case ACCESS_FEATURE.CONVERT_TO_PRO:
      return !isPro;
  }
  return isPro;
};

export const useAccessControl = () => {
  const teamId = useTeamId();
  const { isPro } = api.useIsPro(teamId);
  return (feature: ACCESS_FEATURE) => getAccess(feature, isPro);
};

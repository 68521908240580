export const getRemainingTrailDays = (createdAtStr: string): number => {
  if (!createdAtStr) {
    return 0
  }
  const createdAt = new Date(createdAtStr)
  const trialPeriod = 7;
  const today = new Date();
  const daysUsed = Math.floor((today.getTime() - createdAt.getTime()) / (1000 * 60 * 60 * 24));

  return Math.max(0, trialPeriod - daysUsed);
};
import { Tooltip } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import tooltipClasses from "../../../lib/styles/customizations/Tooltip.module.scss";

const OverflowTip = ({ children, className }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>();
  useEffect(() => {
    setIsOverflow(
      textElementRef?.current?.scrollWidth >
        textElementRef?.current?.clientWidth
    );
  }, []);
  return (
    <Tooltip
      title={children}
      disableHoverListener={!isOverflowed}
      classes={tooltipClasses}
    >
      <div
        ref={textElementRef}
        className={className}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;

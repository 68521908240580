export type UserDetails = {
  address1: string;
  address2: string;
  businessClassification: string;
  businessClassificationName: string;
  businessName: string;
  businessType: string;
  city: string;
  docRequired: boolean;
  docURl: string;
  dwollaDocUrl: string;
  dwollaStatus: string;
  dwollaUrl: string;
  ein: string;
  email: string;
  firstName: string;
  forwarded_email: string;
  fundingSourceUrl: string;
  is_vendor: boolean;
  lastName: string;
  nanonets_api_key: string;
  nanonets_model_id: string;
  parse_email_body: true;
  parse_email_body_no_attachments: false;
  plan: AccountPlan;
  postalCode: string;
  send_mail_to_members: boolean;
  send_mail_to_vendors: boolean;
  ssn: string;
  state: string;
  stripe_key: string;
  type: string;
  vendor_email: string;
  wiseAccountLevel: string;
  wiseProfileId: number;
  wiseToken: string;
  qbProjectFields: boolean;
  glMappingFileUrl: string;
  coaMappingFileUrl: string;
  nanonetsReceiptsModelId: string;
  vendorEmailReceipts: string;
  sendMailToSubmitters: boolean;
  stripeCheckoutFlow: boolean;
  multiGl: boolean;
  stripeAccountId: boolean;
  notificationOnQbExport: boolean;
  qbNotificationEmails: string;
  sendPaymentReminders: boolean;
  erpExportClosingDate: Date | string | null;
  revolutRefreshToken: string;
  revolutJwt: string;
  nanonetsPOModelId: string;
  vendorEmailPO: string;
  poForwardedEmail: string;
  createdAt: string
};

export enum AccountPlan {
  PLAN_PRO = "pro",
  PLAN_FREE = "free",
}

import { VendorDoc } from "../types/vendorDoc";

export const VENDOR_DOCS_MOCK: VendorDoc[] = [
  {
    id: 0,
    vendorId: 0,
    docName: "GST Registration",
    docPath:
      "flow/405f5ef4/2c3ff4fb-d4e8-4871-a4a5-288a02409425/129660037-re.jpg",
  },
  {
    id: 2,
    vendorId: 0,
    docName: "Cancelled Check",
    docPath:
      "flow/405f5ef4/e8fe084e-d39d-4c8e-a206-2292c94f36f4/138844554-re copy 3.jpg",
  },
];

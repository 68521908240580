import { Vendor } from "../types/vendor";

const commonAttrs: Vendor = {
  id: 1,
  name: "",
  created_at: "",
  email: "",
  owner_email: "info@nanonets.com",
  ach_id: "",
  account_no: "",
  iban_number: "",
  bank_name: "",
  default_currency: "",
  paid_amount: 0,
  due_amount: 0,
  stripe_account_id: "",
  display_name: "",
  website: "",
  phone: "",
  address: "",
  invitation_email_sent: false,
  invitation_email_replied: false,
  aba_routing_number: "",
  swift_iban_code: "",
  account_type: "",
  businessType: "company",
  business_tax_id: "",
  dwolla_url: "",
  dwolla_funding_source_url: "",
  glId: 0,
  glIds: "",
  categoryId: 0,
  city: "",
  zip_code: "",
  country_code: "",
  wise_account_id: 0,
  magic_link_version: 0,
  is_currency_fixed: false,
  taxCode: "",
  businessCodeId: 0,
  vendorDocs: [],
  dob: "",
  paymentMethod: 0,
  isSubscription: false,
  spendLimit: 0,
  term: "",
  isItemBasedLineDetail: false,
  customerVendorId: "",
  tier: "",
};

export const VENDORS_MOCK: Vendor[] = [
  {
    ...commonAttrs,
    id: 4,
    name: "Silverstar Alliance Limited",
    email: "finance@silverstar.org.au",
    owner_email: "info@nanonets.com",
    ach_id: "",
    account_no: "521028",
    iban_number: "",
    bank_name: "Eastshore",
    default_currency: "A$",
    paid_amount: 0,
    due_amount: 0,
    stripe_account_id: "",
    display_name: "Silver Alliance Limited",
    website: "",
    phone: "02 6584 1115",
    address: "",
    invitation_email_sent: true,
    invitation_email_replied: false,
    aba_routing_number: "",
    swift_iban_code: "",
    account_type: "",
    businessType: "company",
    business_tax_id: "55611814003",
    created_at: "2022-11-30T21:18:09Z",
    vendorDocs: [
      {
        id: 11,
        vendorId: 6,
        docName: "Cancelled Cheque",
        docPath:
          "flow/405f5ef4/dcb284ba-7ddb-4b07-a57f-5bd88db75c6d/279231814-re copy.jpg",
      },
      {
        id: 17,
        vendorId: 6,
        docName: "GST Registration",
        docPath:
          "flow/405f5ef4/7649595f-22e2-4a72-b69d-f087c64f4eeb/279231814-re copy.jpg",
      },
      {
        id: 12,
        vendorId: 6,
        docName: "PAN",
        docPath:
          "flow/405f5ef4/b3bb52b3-718e-4869-9edd-e3f520ea9708/129660037-re.jpg",
      },
      {
        id: 14,
        vendorId: 6,
        docName: "Uni Agreement",
        docPath:
          "flow/405f5ef4/58955d02-b78b-4c98-9b47-2220e743143a/279231814-re.jpg",
      },
    ],
  },
  {
    ...commonAttrs,
    id: 1,
    name: "ABS Designer",
    owner_email: "info@nanonets.com",
    created_at: "2023-01-10T18:57:01Z",
  },
];

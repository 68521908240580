import { useCallback, useMemo } from "react";
import {
  SnackbarMessage,
  useSnackbar,
  OptionsObject,
  SnackbarKey,
} from "notistack";
import { renderSnackbarContent } from "./components";

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackbar = useCallback(
    (message: SnackbarMessage, options: OptionsObject) => {
      return enqueueSnackbar(message, {
        ...options,
        content: renderSnackbarContent(message, options, closeSnackbar),
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  const notification = useMemo(
    () => ({
      error: (message: SnackbarMessage, options?: OptionsObject) => {
        return showSnackbar(message, { ...options, variant: "error" });
      },
      success: (message: SnackbarMessage, options?: OptionsObject) => {
        return showSnackbar(message, { ...options, variant: "success" });
      },
      info: (message: SnackbarMessage, options?: OptionsObject) => {
        return showSnackbar(message, { ...options, variant: "info" });
      },
      warning: (message: SnackbarMessage, options?: OptionsObject) => {
        return showSnackbar(message, { ...options, variant: "info" });
      },
      remove: (key: SnackbarKey) => closeSnackbar(key),
    }),
    [closeSnackbar, showSnackbar]
  );

  return notification;
};

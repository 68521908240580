import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { useNotification } from "../../ui/utils/notification/notification2";
import { Vendor } from "../types/vendor";
import {
  HttpMethod,
  chainError,
  useDelete,
  useGet,
  usePost,
  useRawRequest,
} from "../util";

export const useVendorById = (teamId: string, vendorId: number) => {
  const get = useGet();
  return useQuery<Vendor, Error>(["vendorById", teamId, vendorId], () =>
    get(`vendors/${vendorId}/?teamid=${teamId}`).catch(
      chainError("Error while fetching vendor")
    )
  );
};

export const useSendVendorEmail = (teamId: string) => {
  const notification = useNotification();
  const req = useRawRequest({
    method: HttpMethod.POST,
    parseFn: async (res) => {
      if (res.ok) {
        return res.json();
      }

      const errorIs4xx = res.status < 500 && res.status >= 400 ? true : false;
      const errorMessage = errorIs4xx
        ? await res.text()
        : "Error while updating the vendor";

      return Promise.reject(new Error(errorMessage));
    },
  });
  return useMutation<Vendor, Error, Partial<Vendor>>(
    (vendor) => req(`vendors/email?teamid=${teamId}`, vendor),
    {
      onSuccess: (data) => {
        notification.success("Email sent successfully");
        queryClient.setQueriesData<Vendor[]>(
          ["vendors", teamId],
          (prevData) => {
            if (prevData) {
              const newData = [...prevData];
              let index = prevData.findIndex((v) => v.id === data.id);
              const newVendor = { ...data };
              if (index > -1) {
                newData[index] = newVendor;
              } else {
                newData.push(newVendor);
              }
              return newData;
            }
          }
        );
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useVendorByToken = (token: string) => {
  const get = useGet(token);
  return useQuery(["vendorByToken", token], () =>
    get(`onboard/special`).catch(chainError("Error while fetching vendor"))
  );
};

export const useVendors = (teamId: string) => {
  const get = useGet();
  return useQuery<void, Error, Vendor[]>(
    ["vendors", teamId],
    () =>
      get(`vendors/?teamid=${teamId}`)
        .then((c) => (c === null ? [] : c))
        .catch(chainError("Error while fetching integrations")),
    {
      onSuccess: (vendors) => {
        vendors.forEach((vendor) => {
          queryClient.setQueryData(["vendorById", teamId, vendor.id], vendor);
        });
      },
    }
  );
};

export const useAddVendor = (teamId: string) => {
  const notification = useNotification();
  const req = useRawRequest({
    method: HttpMethod.POST,
    parseFn: async (res) => {
      if (res.ok) {
        return res.json();
      }

      const errorIs4xx = res.status < 500 && res.status >= 400 ? true : false;
      const errorMessage = errorIs4xx
        ? await res.text()
        : "Error while updating the vendor";

      return Promise.reject(new Error(errorMessage));
    },
  });
  return useMutation<Vendor, Error, { vendor: Partial<Vendor> }>(
    ({ vendor }) => req(`vendors/?teamid=${teamId}`, vendor),
    {
      onSuccess: (data) => {
        notification.success("Vendor Updated Successfully");
        queryClient.setQueryData(
          //TODO: Avoid inconsistent query keys either through TS or constant declarations
          ["vendorById", data.owner_email, data.id],
          data
        );
        queryClient.setQueriesData<Vendor[]>(
          ["vendors", teamId],
          (prevData) => {
            if (prevData) {
              const newData = [...prevData];
              let index = prevData.findIndex((v) => v.id === data.id);
              const newVendor = { ...data };
              if (index > -1) {
                newData[index] = newVendor;
              } else {
                newData.push(data);
              }
              return newData;
            }
          }
        );
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateVendorByToken = (token: string) => {
  const notification = useNotification();
  const req = useRawRequest({
    method: HttpMethod.PUT,
    staticToken: token,
    parseFn: async (res) => {
      if (res.ok) {
        return res;
      }

      const errorIs4xx = res.status < 500 && res.status >= 400 ? true : false;
      const errorMessage = errorIs4xx
        ? await res.text()
        : "Error while updating the vendor";

      return Promise.reject(new Error(errorMessage));
    },
  });
  return useMutation<Vendor, Error, { vendor: Vendor }>(
    ({ vendor }) => req(`onboard/special`, vendor),
    {
      onSuccess: () => {
        notification.success("Vendor Updated Successfully");
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateVendorByEmail = () => {
  const notification = useNotification();
  const post = usePost();
  return useMutation<Vendor, Error, { vendor: Vendor }>(
    ({ vendor }) =>
      post(`invoices/vendorbyemail`, vendor).catch(
        chainError("Error while updating vendor")
      ),
    {
      onSuccess: (data) => {
        notification.success("vendor updated");
        queryClient.setQueriesData(["vendor"], () => data);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useDeleteVendor = (teamId: string) => {
  const notification = useNotification();
  const req = useDelete();
  return useMutation<string[], Error, { vendors: number[] }>(
    ({ vendors }) => {
      const vendorIds = vendors.map((vendor) => `&id=${vendor}`).join("");

      return req(`vendors/?teamid=${teamId}${vendorIds}`).catch(
        chainError("Error while updating vendor")
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Vendor updated");
        queryClient.invalidateQueries(["vendors", teamId]);
        queryClient.invalidateQueries(["invoices", teamId]);
        data?.forEach((id) => {
          queryClient.invalidateQueries(["invoice", id]);
        });
      },
      onError: (error) => {
        notification.error(error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["vendors", teamId]);
        queryClient.invalidateQueries(["invoices", teamId]);
      },
    }
  );
};

import { Approver } from "../types/approver";

export const APPROVERS_MOCK: Approver[] = [
  { email: "mark@nanonets.com", firstName: "", lastName: "" },
  { email: "olivia@nanonets.com", firstName: "", lastName: "" },
  { email: "rachel@nanonets.com", firstName: "", lastName: "" },
  { email: "shawn@nanonets.com", firstName: "", lastName: "" },
  {
    email: "shiva.bandari@nanonets.com",
    firstName: "Shiva",
    lastName: "Bandari",
  },
];

import React from "react";
import { Alert } from "@mui/material";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export const renderSnackbarContent = (
  message: SnackbarMessage,
  options: OptionsObject,
  closeSnackbar: (key?: SnackbarKey) => void
) => {
  const severity = options.variant === "default" ? "info" : options.variant;
  return (
    <Alert
      style={{ minWidth: 600 }}
      severity={severity}
      onClose={() => closeSnackbar(options.key)}
    >
      {message}
    </Alert>
  );
};

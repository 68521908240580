import { useMutation, useQuery } from "react-query";
import queryClient from "../../../lib/queryClient";
import { useNotification } from "../../../ui/utils/notification/notification2";
import { PaymentStatus } from "../../types/payment";
import { usePost, chainError, useGet, useDelete } from "../../util";

export const useTransferPayment = (teamId: string) => {
  const notification = useNotification();
  const post = usePost();

  return useMutation<
    Record<string, PaymentStatus>,
    Error,
    { invoiceIds: string[]; referenceId: string }
  >(
    ({ invoiceIds, referenceId }) => {
      return post(`invoices/transferPayment/?teamid=${teamId}`, {
        invoiceIds,
        referenceId,
      }).catch(chainError("Error while making payment"));
    },
    {
      onSuccess: (data, { invoiceIds }) => {
        const failed = Object.values(data).some(
          (status) => status !== PaymentStatus.SUCCESS
        );

        if (failed) {
          notification.error("Error while making payment");
        } else {
          notification.success("Payment completed successfully");
        }

        queryClient.invalidateQueries(["stripeBalance", teamId]);
        invoiceIds.forEach((id) => {
          queryClient.invalidateQueries(["invoice", id]);
          queryClient.invalidateQueries(["payments", id]);
        });
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useCreatePaymentLink = (teamId: string, invoiceIds: string[]) => {
  const notification = useNotification();
  const post = usePost();
  return useQuery(
    ["paymentLink", teamId, ...invoiceIds],
    () => {
      return post(`invoices/createPaymentLink/?teamid=${teamId}`, {
        invoiceIds,
      });
    },
    {
      retry: 1,
      enabled: false,
      onError: () => {
        notification.error("Error fetching stripe checkout link");
      },
    }
  );
};

export const useStripeConnectLink = (teamId: string) => {
  const notification = useNotification();
  const get = useGet();
  return useQuery(
    ["connectLink", teamId],
    () => {
      return get(`stripe/connectLink/?teamid=${teamId}`);
    },
    {
      onError: () => {
        notification.error("Error fetching stripe connect link");
      },
    }
  );
};

export const useStripeConfirmConnection = (teamId: string) => {
  const notification = useNotification();
  const post = usePost();

  return useMutation<void, Error, { code: string }>(
    (request) => {
      return post(`stripe/connection/?teamid=${teamId}`, request);
    },
    {
      onSuccess: () => {
        notification.success("Stripe connected successfully");
        queryClient.invalidateQueries(["teamdetails", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useStripeDeleteConnection = (teamId: string) => {
  const notification = useNotification();
  const req = useDelete();

  return useMutation<void, Error, void>(
    () => req(`stripe/connection/?teamid=${teamId}`),
    {
      onSuccess: () => {
        notification.success("Stripe connection removed successfully");
        queryClient.invalidateQueries(["teamdetails", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useSyncStripeAccounts = (teamId: string) => {
  const notification = useNotification();
  const req = usePost();

  return useMutation<void, Error, void>(
    () => req(`stripe/sync/accounts/?teamid=${teamId}`),
    {
      onSuccess: () => {
        notification.success("Stripe accounts synced successfully");
        queryClient.invalidateQueries(["vendors", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

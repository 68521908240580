import { TeamUserRole } from "../../api/types/team";
import { DOC_TYPE, Invoice, PaymentMethod, StarOption } from "../types/invoice";

export const EMPTY_INVOICE: Invoice = Object.freeze({
  Id: "",
  From_email: "",
  To_email: "",
  Status: "",
  Url: "",
  Md5: "",
  Date: new Date(),
  Extracted_vendor_address: "",
  Extracted_vendor_name: "",
  Extracted_vendor_email: "",
  Extracted_doc_number: "",
  Extracted_doc_date: "",
  Final_doc_date: "",
  Extracted_due_date: "",
  Final_due_date: "",
  Extracted_amount: "",
  Extracted_currency: "",
  Extracted_buyer_name: "",
  Extracted_bank_name: "",
  Extracted_bank_account_number: "",
  Extracted_bank_payto: "",
  DownloadPath: "",
  PaymentDate: "",
  Paid_by: "",
  Email_sent: 0,
  Email_vendor_sent: 0,
  Slack_message_sent: 0,
  Email: "",
  ContentType: "",
  Duplicate: 0,
  VendorId: 0,
  Approved_by: null,
  Rejected_by: null,
  Frequency: "",
  FirstApprover: "",
  SecondApprover: "",
  FinalApprover: "",
  Comments: "",
  QbFlag: "",
  ApproveRejectComment: "",
  Currency: "",
  InlineItems: [],
  IsArchived: false,
  UpdatedAt: new Date(),
  LifecycleStage: "",
  SoftDeletedBy: "",
  SoftDeletedAt: new Date(),
  NudgedByVendor: false,
  Extracted_vendor_phone: "",
  Extracted_vendor_vat_number: "",
  Extracted_vendor_website: "",
  IsProcessing: false,
  Source: "",
  ArchivedAt: "",
  HardDeletedBy: "",
  HardDeletedAt: null,
  Project: "",
  Class: "",
  PaymentMethod: 0,
  PaymentSource: "",
  Term: "",
  Memo: "",
  TaxCode: "",
  CreditCardNumber: "",
  DocType: DOC_TYPE.INVOICE,
  PaymentScheduledAt: "",
  ScheduledPaymentStatus: "",
  ClientId: "",
  FinalBuyerName: "",
  IfscCode: "",
  CGST: "",
  SGST: "",
  IGST: "",
  GstNumber: "",
  PayableAmount: 0,
  PostingDate: new Date(),
  FlowFileId: "",
  FlowFilePath: "",
  TotalTax: "",
  ShippingCost: "",
  RetryCount: 0,
  DriveFileId: "",
  SourceDetails: "",
  TxnAmount: 0,
  TxnCurrency: "",
  IsReadonly: false,
  AllowForceUpdate: false,
  ReconciliationDone: false,
  IsAutoApproved: false,
  CustomerPoNumber: "",
  CustomerAccountNumber: "",
  Location: "",
  Starred: false,
  CustomTVA: "",
  CustomTotalHT: "",
  CustomDepartment: "",
  MatchedTillDate: "",
});

export enum InvoiceStatus {
  FETCHED = "Fetched",
  APPROVED = "Approved",
  PAID = "Paid",
  REJECTED = "Rejected",
  PROCESSING = "Processing",
  FAILED = "Failed",
}

export const INVOICE_STATUS = Object.freeze({
  FETCHED: "Fetched",
  APPROVED: "Approved",
  FIRST: "First_Approved",
  SECOND: "Second_Approved",
  PAID: "Paid",
  REJECTED: "Rejected",
  PROCESSING: "Processing",
  FAILED: "Failed",
});

export const USER_ROLE = Object.freeze({
  OWNER: "Owner",
  ADMIN: "Admin",
  CONTROLLER: "Controller",
  APPROVER: "Approver",
  EDITOR: "Editor",
  VIEW_ONLY_APPROVER: "View-Only Approver",
  VIEWER: "Viewer",
  COMPANY_ADMIN: "Company Admin",
});

export const ALLOWED_CONTENT_TYPE = Object.freeze({
  PDF: "application/pdf",
  JPEG: "image/jpeg",
  PNG: "image/png",
  JPG: "image/jpg",
});

export const FREQUENCY_LIST = ["One-Time", "Recurring"];

export const ROLES_AUTHORIZED_FOR_AUDITOR = [
  TeamUserRole.OWNER,
  TeamUserRole.ADMIN,
  TeamUserRole.CONTROLLER,
  TeamUserRole.COMPANY_ADMIN,
];
export const ROLES_AUTHORIZED_FOR_CONTROLLER = [
  TeamUserRole.OWNER,
  TeamUserRole.ADMIN,
  TeamUserRole.COMPANY_ADMIN,
];
export const ROLES_CONTROLLER_HAS_ACCESS = [
  TeamUserRole.AP_TEAM_MEMBER,
  TeamUserRole.BUSINESS_USER,
  TeamUserRole.VIEW_ONLY_APPROVER,
  TeamUserRole.EMPLOYEE,
  TeamUserRole.AUDITOR,
];
export const ROLES_AP_HAS_ACCESS = [
  TeamUserRole.BUSINESS_USER,
  TeamUserRole.VIEW_ONLY_APPROVER,
  TeamUserRole.EMPLOYEE,
];

export const DEFAULT_WISE_ACCOUNT = {
  amount: 0.0,
  wiseToken: "",
  wiseProfileId: 0,
  sourceCurrency: "USD",
  account: {
    accountHolderName: "",
    currency: "USD",
    id: 0,
    type: "",
    details: {
      address: {
        city: "",
        state: "",
        countryCode: "",
        postCode: "",
        firstLine: "",
      },
      legalType: "",
      abartn: "",
      ifscCode: "",
      swiftCode: "",
      accountType: "",
      accountNumber: "",
      email: "",
      iban: "",
      sortCode: "",
    },
  },
};

export const DEFAULT_REVOLUT_PAY_REQUEST = {
  invoiceId: "",
  accountId: "",
  receiver: {
    counterPartyId: "",
    accountId: "",
  },
  amount: 0.0,
  currency: "",
};

export const ROLE_VALUE = Object.freeze({
  Viewer: 1,
  Editor: 2,
  "View-Only Approver": 3,
  Approver: 4,
  Controller: 5,
  Admin: 6,
  "Company Admin": 7,
  Owner: 8,
});

export const EMPTY_FILTERS = Object.freeze({
  items: [],
  linkOperator: "and",
  quickFilterLogicOperator: "and",
  quickFilterValues: [],
});

export const INVOICE_LIFECYCLE_STAGE = Object.freeze({
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  SOFT_DELETED: "SOFT_DELETED",
});

export enum FLOW_SCREEN {
  INVOICE = "invoice",
  RECEIPT = "receipt",
  PO = "po",
  ARCHIVE = "archive",
  TRASH = "trash",
  VENDOR = "vendor",
  APPROVALS = "approvals",
  TEAM = "team",
}

export const INVOICE_EMPTY_FILTERS = Object.freeze({
  lifecycleStage: INVOICE_LIFECYCLE_STAGE.ACTIVE,
  docType: DOC_TYPE.INVOICE,
});

export const RECEIPTS_EMPTY_FILTERS = Object.freeze({
  lifecycleStage: INVOICE_LIFECYCLE_STAGE.ACTIVE,
  docType: DOC_TYPE.RECEIPT,
});

export const ARCHIVED_INVOICE_EMPTY_FILTERS = Object.freeze({
  lifecycleStage: INVOICE_LIFECYCLE_STAGE.ARCHIVED,
  docType: DOC_TYPE.INVOICE,
});

export const TRASH_EMPTY_FILTERS = Object.freeze({
  lifecycleStage: INVOICE_LIFECYCLE_STAGE.SOFT_DELETED,
  docType: DOC_TYPE.INVOICE,
});

export const PUB_SUB_EVENTS_MAP = new Map()
  .set(FLOW_SCREEN.TRASH, "Trash List Details")
  .set(FLOW_SCREEN.RECEIPT, "Receipt List Details")
  .set(FLOW_SCREEN.ARCHIVE, "Archived List Details")
  .set(FLOW_SCREEN.INVOICE, "Invoice List Details")
  .set(FLOW_SCREEN.VENDOR, "Vendor List Details")
  .set(FLOW_SCREEN.APPROVALS, "Approvals Details")
  .set(FLOW_SCREEN.TEAM, "Team List Details");

export const EMPTY_FILTERS_MAP = new Map()
  .set(FLOW_SCREEN.TRASH, TRASH_EMPTY_FILTERS)
  .set(FLOW_SCREEN.RECEIPT, RECEIPTS_EMPTY_FILTERS)
  .set(FLOW_SCREEN.ARCHIVE, ARCHIVED_INVOICE_EMPTY_FILTERS)
  .set(FLOW_SCREEN.INVOICE, INVOICE_EMPTY_FILTERS);

export const SCREEN_STATUS_MAP = new Map()
  .set(FLOW_SCREEN.TRASH, [
    INVOICE_STATUS.APPROVED,
    INVOICE_STATUS.PAID,
    INVOICE_STATUS.REJECTED,
    INVOICE_STATUS.FETCHED,
    INVOICE_STATUS.FIRST,
    INVOICE_STATUS.SECOND,
  ])
  .set(FLOW_SCREEN.RECEIPT, [
    INVOICE_STATUS.FETCHED,
    INVOICE_STATUS.FIRST,
    INVOICE_STATUS.SECOND,
  ])
  .set(FLOW_SCREEN.PO, [
    INVOICE_STATUS.FETCHED,
    INVOICE_STATUS.FIRST,
    INVOICE_STATUS.SECOND,
  ])
  .set(FLOW_SCREEN.ARCHIVE, [INVOICE_STATUS.PAID])
  .set(FLOW_SCREEN.INVOICE, [
    INVOICE_STATUS.FETCHED,
    INVOICE_STATUS.FIRST,
    INVOICE_STATUS.SECOND,
  ]);

export type FlowScreenContext = {
  screen: FLOW_SCREEN;
  readonly: boolean;
  title: string;
};

export const CONTEXT_VALUE_MAP = new Map<FLOW_SCREEN, FlowScreenContext>()
  .set(FLOW_SCREEN.TRASH, {
    screen: FLOW_SCREEN.TRASH,
    readonly: true,
    title: "Trash",
  })
  .set(FLOW_SCREEN.RECEIPT, {
    screen: FLOW_SCREEN.RECEIPT,
    readonly: false,
    title: "Receipts",
  })
  .set(FLOW_SCREEN.PO, {
    screen: FLOW_SCREEN.PO,
    readonly: false,
    title: "Purchase Orders",
  })
  .set(FLOW_SCREEN.ARCHIVE, {
    screen: FLOW_SCREEN.ARCHIVE,
    readonly: true,
    title: "Archive",
  })
  .set(FLOW_SCREEN.INVOICE, {
    screen: FLOW_SCREEN.INVOICE,
    readonly: false,
    title: "Invoices",
  });

export const DWOLLA_CONTROLLER_DOC_TYPES = Object.freeze([
  {
    docType: `Non-expired State Issued Driver’s License/Identification Card`,
    dwollaName: "license",
  },
  {
    docType: "Federal Employment Authorization Card",
    dwollaName: "idCard",
  },
  {
    docType: "Non-expired US Passport",
    dwollaName: "idCard",
  },
  {
    docType: "US Visa",
    dwollaName: "idCard",
  },
  {
    docType: "Non US Passport",
    dwollaName: "passport",
  },
]);

export const DWOLLA_BUSINESS_DOC_TYPES = Object.freeze([
  {
    docType: "EIN Letter (IRS-issued SS4 confirmation letter)",
    dwollaName: "other",
  },
]);

export const EMPTY_OWNER_DETAILS = Object.freeze({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  ssn: "",
  passport: {
    number: "",
    country: "",
  },
  address: {
    address1: "",
    city: "",
    stateProvinceRegion: "",
    country: "",
    postalCode: "",
  },
});

export const EMPTY_CUSTOMER_DETAILS = Object.freeze({
  firstName: "",
  lastName: "",
  email: "",
  type: "business",
  address1: "",
  city: "",
  state: "",
  postalCode: "",
  businessName: "",
  businessType: "",
  businessTypeName: "",
  businessClassification: "",
  businessClassificationName: "",
  ein: "",
  ssn: "",
  dateOfBirth: "",
  controller: {
    firstName: "",
    lastName: "",
    title: "",
    dateOfBirth: "",
    ssn: "",
    passport: {
      number: "",
      country: "",
    },
    address: {
      address1: "",
      address2: "",
      city: "",
      stateProvinceRegion: "A",
      postalCode: "",
      country: "",
    },
  },
});

export const EMPTY_APPROVER = {
  approval_rule_id: "",
  approver_id: "",
  approver_rank: 0,
  is_mandatory: true,
};

export const DEFAULT_FILTER = {
  approval_rule_id: "",
  filter_condition: "equals",
  filter_column: "vendor_display_name",
  filter_values: [""],
};

export const ONE_MB = 1024;
export const DWOLLA_INDIVIDUAL_DOC_EXTENSIONS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];
export const DWOLLA_BUSINESS_DOC_EXTENSIONS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

export const CurrencySymbolToCode = Object.freeze({
  $: "USD",
  "€": "EUR",
  "£": "GBP",
  "₹": "INR",
  "¥": "CNY",
  "NZ $": "NZD",
  A$: "AUD",
  C$: "CAD",
  CHF: "CHF",
  HK$: "HKD",
  "₪": "ILS",
  AED: "AED",
  CZK: "CZK",
  kr: "DKK",
});

export const CurrencyCodeToSymbol = Object.freeze({
  USD: "$",
  EUR: "€",
  GBP: "£",
  INR: "₹",
  CNY: "¥",
  NZD: "NZ $",
  AUD: "A$",
  CAD: "C$",
  CHF: "CHF",
  HKD: "HK$",
  ILS: "₪",
  AED: "AED",
  CZK: "CZK",
  DKK: "kr",
});

export const PRE_DEFINED_PAYMENT_METHOD: PaymentMethod[] = [
  {
    id: -1,
    name: "Wise",
  },
  {
    id: -2,
    name: "Stripe",
  },
  {
    id: -3,
    name: "Bank ACH",
  },
  {
    id: -4,
    name: "Revolut",
  },
];

export const PRE_DEFINED_TERMS = [
  {
    id: "Net 15",
    label: "Net 15",
  },
  {
    id: "Net 30",
    label: "Net 30",
  },
  {
    id: "Net 60",
    label: "Net 60",
  },
  {
    id: "Immediate",
    label: "Immediate",
  },
];

export const STAR_OPTIONS: StarOption[] = [
  { id: 0, name: "Prioritised" },
  {
    id: 1,
    name: "Non-Prioritised",
  },
];

export const LINE_ITEMS_DEFAULT_HEADERS = [
  "GL Code",
  "Description",
  "Line_Amount",
  "Tax",
];

export const CountryList = Object.freeze({
  Canada: "CA",
  // "Chile": "CL",
  // "China": "CN",
  Cyprus: "CY",
  France: "FR",
  Germany: "DE",
  India: "IN",
  Spain: "ES",
  "United Kingdom": "UK",
  "United States Of America": "US",
  Israel: "IL",
});

export const FranceSupportedCountryList = Object.freeze({
  Austria: "AT",
  Belgium: "BE",
  Bulgaria: "BG",
  Switzerland: "CH",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Germany: "DE",
  Denmark: "DK",
  Estonia: "EE",
  Spain: "ES",
  Finland: "FI",
  France: "FR",
  "United Kingdom": "GB",
  Gibraltar: "GI",
  Greece: "GR",
  Croatia: "HR",
  Hungary: "HU",
  Ireland: "IE",
  Italy: "IT",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Latvia: "LV",
  Malta: "MT",
  Netherlands: "NL",
  Norway: "NO",
  Poland: "PL",
  Portugal: "PT",
  Romania: "RO",
  Sweden: "SE",
  Slovenia: "SI",
  Slovakia: "SK",
  "United Arab Emirates": "AE",
  Australia: "AU",
  Canada: "CA",
  "Hong Kong": "HK",
  Indonesia: "ID",
  Japan: "JP",
  Mexico: "MX",
  Malaysia: "MY",
  "New Zealand": "NZ",
  Singapore: "SG",
  Thailand: "TH",
  "United States": "US",
});

//todo : implement feature flagging
export const TaxEnabledTeam = [
  "finances@medbelle.com",
  "administration@antimodular.com",
  "m@ziser.co.uk",
  "josip@medbelle.com",
  "yash@nanonets.com",
];

export const vendorTaxFieldEnabledTeams = [
  "yash@nanonets.com",
  "josip@medbelle.com",
];

export const vendorBusinessCodeEnabledTeams = ["josip@medbelle.com"];
export const clientIdEnabledTeams = ["m@ziser.co.uk", "pankaj@nanonets.com"];

export const indianTeams = [
  // "pankaj@nanonets.com",
  "shiva.bandari@nanonets.com",
  "uni.nanonets@gmail.com",
  "payments@uni.club",
  "yash@nanonets.com",
  "rohan@nanonets.com",
];

export const extraValidations = [
  "administration@antimodular.com",
  "yash@nanonets.com",
];

import * as approverMock from "./queries/invoice/approver.mock";
import * as approvalsMock from "./queries/approvals.mock";
import * as user from "./queries/user.mock";
import * as team from "./queries/team/team.mock";
import * as businessCode from "./queries/businessCode.mock";
import * as vendorDoc from "./queries/vendor/doc.mock";
import * as file from "./queries/cloud/file.mock";
import * as employee from "./queries/employee/employee.mock";
import * as stripe from "./queries/payment/stripe.mock";
import * as vendor from "./queries/vendor.mock";
import * as invoice from "./queries/invoice.mock";
import * as invoice2 from "./queries/invoice/invoice.mock";
import * as spend from "./queries/analytics/spend.mock";
import * as category from "./queries/categories.mock";
import * as invoiceExport from "./queries/invoice/export.mock";
import * as gl from "./queries/gl.mock";
import * as revolut from "./queries/payment/revolut";
import * as payments from "./queries/payments";
import * as exportQueries from "./queries/export.mock";
import * as quickbooks from "./queries/external/quickbooks.mock";
import * as d365 from "./queries/external/d365.mock";
import * as admin from "./queries/admin/admin.mock";

const queriesMock = {
  ...approverMock,
  ...approvalsMock,
  ...user,
  ...team,
  ...businessCode,
  ...vendorDoc,
  ...file,
  ...employee,
  ...stripe,
  ...vendor,
  ...invoice,
  ...invoice2,
  ...spend,
  ...category,
  ...invoiceExport,
  ...gl,
  ...revolut,
  ...payments,
  ...exportQueries,
  ...quickbooks,
  ...d365,
  ...admin,
};

export default queriesMock;

import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../lib/styles/root.css";
import { Auth0Provider } from "../../api/react-auth0-spa";
import config from "../../lib/auth_config.json";
import history from "../../lib/history";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { QueryClientProvider } from "react-query";
import queryClient from "../../lib/queryClient";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import theme from "../../lib/styles/theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { FCC } from "../../lib/types/common/react";
import NanoUIProvider from "../../ui/Provider";
import { ReactQueryDevtools } from "react-query/devtools";

setDefaultBreakpoints([
  { xsmall: 0 }, // all mobile devices
  { small: 576 }, // mobile devices
  { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  { large: 992 }, // smaller laptops
  { xlarge: 1200 }, // laptops and desktops
]);

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState?) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const Providers: FCC = ({ children }) => {
  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      audience={config.audience} // NEW - specify the audience value
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <Router>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <QueryClientProvider client={queryClient}>
            <BreakpointProvider>
              <ThemeProvider theme={theme}>
                <NanoUIProvider>{children}</NanoUIProvider>
              </ThemeProvider>
            </BreakpointProvider>

            {process.env.REACT_APP_RQDEV === "true" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </SnackbarProvider>
      </Router>
    </Auth0Provider>
  );
};

export default Providers;

import { Interval } from "date-fns";
import { Dispatch, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { DOC_TYPE } from "../../lib/types/invoice";
import { MAX_TIMESTAMP } from "../shared/Filter/DateFilter";
import { extractQueryParams } from "./url";

export type AnalyticsPageState = {
  filters: {
    docType: DOC_TYPE[];
    category: number[];
    vendor: number[];
    status: string[];
    invoiceDate: Interval;
    exportDate: Interval;
    dueDate: Interval;
    exportStatus: string[];
  };
  page: number;
  rowsPerPage: number;
  teamId: string;
};

export type AnalyticsPageDispatch = Dispatch<AnalyticsPageAction>;

export const DEFAULT_STATE: AnalyticsPageState = {
  page: 0,
  rowsPerPage: 100,
  filters: {
    docType: [],
    category: [],
    vendor: [],
    status: [],
    exportStatus: [],
    invoiceDate: {
      start: 0,
      end: MAX_TIMESTAMP,
    },
    exportDate: {
      start: 0,
      end: MAX_TIMESTAMP,
    },
    dueDate: {
      start: 0,
      end: MAX_TIMESTAMP,
    },
  },
  teamId: "",
};

export enum AnalyticsPageActionType {
  PAGE = "page",
  ROWS_PER_PAGE = "rowsPerPage",
  SET_CATEGORIES = "category",
  SET_DOC_TYPES = "docType",
  SET_VENDORS = "vendor",
  SET_STATUSES = "status",
  SET_INVOICE_DATE = "invoiceDate",
  SET_DUE_DATE = "dueDate",
  SET_EXPORT_DATE = "exportDate",
  SET_EXPORT_STATUS = "exportStatus",
  CLEAR_FILTERS = "clear",
}

export const useAnalyticsPageReducer = () => {
  const location = useLocation();
  const initData = extractQueryParams(location.search);
  return useReducer(reducer, initData);
};

type AnalyticsPageAction = {
  type: AnalyticsPageActionType;
} & Partial<{
  docType: DOC_TYPE[];
  category: number[];
  vendor: number[];
  status: string[];
  exportStatus: string[];
  invoiceDate: Interval;
  exportDate: Interval;
  dueDate: Interval;
  page: number;
  rowsPerPage: number;
}>;

function reducer(
  state: AnalyticsPageState,
  action: AnalyticsPageAction
): AnalyticsPageState {
  switch (action.type) {
    case AnalyticsPageActionType.PAGE:
      return { ...state, page: action.page };
    case AnalyticsPageActionType.ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.rowsPerPage };
    case AnalyticsPageActionType.SET_CATEGORIES:
      return {
        ...state,
        filters: { ...state.filters, category: action.category },
      };
    case AnalyticsPageActionType.SET_DOC_TYPES:
      return {
        ...state,
        filters: { ...state.filters, docType: action.docType },
      };
    case AnalyticsPageActionType.SET_INVOICE_DATE:
      return {
        ...state,
        filters: { ...state.filters, invoiceDate: action.invoiceDate },
      };
    case AnalyticsPageActionType.SET_DUE_DATE:
      return {
        ...state,
        filters: { ...state.filters, dueDate: action.dueDate },
      };
    case AnalyticsPageActionType.SET_EXPORT_DATE:
      return {
        ...state,
        filters: { ...state.filters, exportDate: action.exportDate },
      };
    case AnalyticsPageActionType.SET_STATUSES:
      return {
        ...state,
        filters: { ...state.filters, status: action.status },
      };
    case AnalyticsPageActionType.SET_VENDORS:
      return {
        ...state,
        filters: { ...state.filters, vendor: action.vendor },
      };
    case AnalyticsPageActionType.SET_EXPORT_STATUS:
      return {
        ...state,
        filters: { ...state.filters, exportStatus: action.exportStatus },
      };
    case AnalyticsPageActionType.CLEAR_FILTERS:
      return { ...DEFAULT_STATE, teamId: state.teamId };
    default:
      throw new Error(`Undefined action type : ${action.type}`);
  }
}

import { useAuth0 } from "./react-auth0-spa";

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

const BASE_PATH = "/api/v1/";

const asJson = async (res) => {
  if (!res.ok) {
    try {
      const errorMessage = await res.text();
      return Promise.reject(new Error(errorMessage));
    } catch (e) {
      return Promise.reject(new Error("Request failed"));
    }
  }
  if (res.status === 204) return res;
  try {
    const val = await res.json();
    return val;
  } catch (e) {
    // Ignoring json parse errors;
    console.error("JSON parse error", e);
    return res;
  }
};

const useRequest = (method: HttpMethod, staticToken?: string | false) => {
  return useRawRequest({ method, parseFn: asJson, staticToken });
};

export const useRawRequest = ({
  method = HttpMethod.GET,
  parseFn = asJson,
  staticToken = undefined,
}) => {
  const { getTokenSilently } = useAuth0();

  return (path: string, data?) => {
    const url = path.startsWith("https://") ? path : BASE_PATH + path;
    const tokenPromise: Promise<string | false> =
      typeof staticToken === "string"
        ? Promise.resolve(staticToken)
        : staticToken === false
        ? Promise.resolve(false)
        : getTokenSilently();

    return tokenPromise
      .then((token) => {
        const init: RequestInit = { method };
        if (token !== false) {
          init.headers = { Authorization: `Bearer ${token}` };
        }
        if (
          method === HttpMethod.POST ||
          method === HttpMethod.PATCH ||
          method === HttpMethod.PUT
        ) {
          init.body = data instanceof FormData ? data : JSON.stringify(data);
        }
        return fetch(url, init);
      })
      .then(parseFn);
  };
};

export const useGet = (token?: string) => useRequest(HttpMethod.GET, token);
export const usePost = (token?: string) => useRequest(HttpMethod.POST, token);
export const usePut = (token?: string | false) =>
  useRequest(HttpMethod.PUT, token);
export const usePatch = (token?: string) => useRequest(HttpMethod.PATCH, token);
export const useDelete = (token?: string) =>
  useRequest(HttpMethod.DELETE, token);

export const chainError = (msg: string) => () => Promise.reject(new Error(msg));

// To be used in mocks
export const defaultMutationFn = <P, T>(p: P) => {
  console.log(p);
  return Promise.resolve() as Promise<T>;
};

export const useDownloadRequest = (fileName: string) => {
  return useRawRequest({
    parseFn: (r) => {
      const response = r.blob();

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return Promise.resolve(fileName);
    },
  });
};

import { useQuery } from "react-query";
import { Employee } from "../../types/employee";
import { chainError, useGet } from "../../util";

export const useEmployees = (teamId) => {
  const get = useGet();
  return useQuery<Employee[]>(["employees", teamId], () =>
    get(`employees/?teamid=${teamId}`).catch(
      chainError("Error while fetching employees")
    )
  );
};

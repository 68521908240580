import { ApprovalRule } from "../../lib/types/approval";

export const APPROVALS_MOCK: ApprovalRule[] = [
  {
    id: "687dea18-c588-4cc6-b8b3-a5963354e347",
    name: "fafd",
    owner_email: "shiva.bandari@nanonets.com",
    valid_for_invoices: true,
    valid_for_receipts: false,
    valid_for_po: false,
    approval_rule_approvers: [
      {
        id: 5,
        approval_rule_id: "687dea18-c588-4cc6-b8b3-a5963354e347",
        approver_id: "mark@nanonets.com",
        approver_rank: 1,
        is_mandatory: true,
      },
    ],
    approval_rule_filters: [
      {
        id: 4,
        approval_rule_id: "687dea18-c588-4cc6-b8b3-a5963354e347",
        filter_condition: "contains",
        filter_column: "vendor_display_name",
        filter_values: ["df"],
      },
    ],
  },
  {
    id: "88afa93b-1044-4797-821c-7eda545b33e2",
    name: "sdfas",
    owner_email: "shiva.bandari@nanonets.com",
    valid_for_invoices: true,
    valid_for_receipts: true,
    valid_for_po: false,
    approval_rule_approvers: [
      {
        id: 12,
        approval_rule_id: "88afa93b-1044-4797-821c-7eda545b33e2",
        approver_id: "mark@nanonets.com",
        approver_rank: 1,
        is_mandatory: true,
      },
    ],
    approval_rule_filters: [
      {
        id: 10,
        approval_rule_id: "88afa93b-1044-4797-821c-7eda545b33e2",
        filter_condition: "equals",
        filter_column: "vendor_display_name",
        filter_values: ["SH"],
      },
    ],
  },
  {
    id: "f63cba0c-ff85-4124-8f85-85a47cbbf76b",
    name: "sdfa",
    owner_email: "shiva.bandari@nanonets.com",
    valid_for_invoices: true,
    valid_for_receipts: false,
    valid_for_po: false,
    approval_rule_approvers: [
      {
        id: 11,
        approval_rule_id: "f63cba0c-ff85-4124-8f85-85a47cbbf76b",
        approver_id: "mark@nanonets.com",
        approver_rank: 1,
        is_mandatory: true,
      },
    ],
    approval_rule_filters: [
      {
        id: 9,
        approval_rule_id: "f63cba0c-ff85-4124-8f85-85a47cbbf76b",
        filter_condition: "starts_with",
        filter_column: "vendor_display_name",
        filter_values: ["PE"],
      },
    ],
  },
  {
    id: "f648f0ba-6708-4eb5-9687-4d36c76a9acc",
    name: "all to me",
    owner_email: "shiva.bandari@nanonets.com",
    valid_for_invoices: true,
    valid_for_receipts: true,
    valid_for_po: false,
    approval_rule_approvers: [
      {
        id: 8,
        approval_rule_id: "f648f0ba-6708-4eb5-9687-4d36c76a9acc",
        approver_id: "shiva.bandari@nanonets.com",
        approver_rank: 1,
        is_mandatory: true,
      },
      {
        id: 9,
        approval_rule_id: "f648f0ba-6708-4eb5-9687-4d36c76a9acc",
        approver_id: "mark@nanonets.com",
        approver_rank: 2,
        is_mandatory: true,
      },
    ],
    approval_rule_filters: [
      {
        id: 7,
        approval_rule_id: "f648f0ba-6708-4eb5-9687-4d36c76a9acc",
        filter_condition: "does_not_contains",
        filter_column: "vendor_display_name",
        filter_values: ["asdlkdjsafsdsa"],
      },
    ],
  },
];

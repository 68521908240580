import { useMutation, useQuery } from "react-query";
import { defaultMutationFn } from "../../util";
import { ExportHistory } from "../../types/export";

export const useDownloadExcel = (teamId) => {
  return useMutation<string, Error, { ids: string[] }>(defaultMutationFn);
};

export const useExportHistory = (invoiceId: string, teamId: string) => {
  return useQuery<ExportHistory[], Error>(
    ["exportHistory.mock", invoiceId],
    () => []
  );
};

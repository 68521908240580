import React, { useEffect, useState, lazy, Suspense } from "react";
import { useAuth0 } from "../../api/react-auth0-spa";
import { Switch, Route, useHistory } from "react-router-dom";
import { useUrlSearchParams } from "../../api/queries/misc";
import { FLOW_SCREEN } from "../../lib/constants/constants";
import Connects from "../Connects/Connects";
import AnalyticsPage from "../AnalyticsPage/AnalyticsPage";
import ConvertProCTA from "../../ui/components/user/ConvertProCTA";
// import smartlookClient from 'smartlook-client';

const Login = lazy(() => import("../Home/Login/Login"));
// const Details = lazy(() => import("../Details/Details"));
const Details = lazy(() => import("../Details/DetailsNew/Details"));
const Teams = lazy(() => import("../Teams/Teams"));
const VendorsPage = lazy(() => import("../Vendors/VendorsPage"));
const VendorDetailsPage = lazy(
  () => import("../Vendors/VendorDetails/VendorDetailsPage")
);
const Home = lazy(() => import("../Home/Home"));
const Layout = lazy(() => import("../Layout/Layout"));
const Preview = lazy(() => import("../Preview/Preview"));
const Payments = lazy(() => import("../Payments/Payments"));
const Settings = lazy(() => import("../Settings/Settings"));
const Approvals = lazy(() => import("../Approvals/Approvals"));
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const AdminPanel = lazy(() => import("../AdminPanel/AdminPanel"));
const FeatureFlags = lazy(() => import("../AdminPanel/FeatureFlags"));
const SuperAdmin = lazy(() => import("../AdminPanel/SuperAdmin"));
const VendorRoutes = lazy(() => import("./VendorRoutes"));
const SellerSettings = lazy(() => import("../Settings/SellerSettings"));

/**
 * Make sure a path is either an authenticated page or not.
 * Do not have same path behave differently based on isAuthenticated.
 *
 * e.g. /vendor/ deals with vendor onboarding pages (token based auth)
 *      /vendors/ deals with vendor list & details (regular auth)
 *
 */

const App = () => {
  const { isAuthenticated, user, loading, getTokenSilently } = useAuth0();
  const { teamId } = useUrlSearchParams();
  const [deafultTeamForUser, setDefaultTeam] = useState(
    localStorage.getItem("ap_nanonets_default_team")
  );
  const history = useHistory();

  const getAllTeamIds = async () => {
    try {
      const token = await getTokenSilently();
      const response = await fetch("/api/v1/allteamids", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        if (!deafultTeamForUser) {
          if (responseData && responseData?.length > 0) {
            const activeTeams = responseData?.filter(
              (team) => team?.status === "active"
            );
            if (activeTeams?.length > 0) {
              setDefaultTeam(activeTeams[0]?.email);
              localStorage.setItem(
                "ap_nanonets_default_team",
                activeTeams[0]?.email
              );
            } else {
              setDefaultTeam(user?.email);
              localStorage.setItem("ap_nanonets_default_team", user?.email);
            }
          } else {
            setDefaultTeam(user?.email);
            localStorage.setItem("ap_nanonets_default_team", user?.email);
          }
        }
      } else {
        setDefaultTeam(user?.email);
        localStorage.setItem("ap_nanonets_default_team", user?.email);
      }
    } catch (error) {
      setDefaultTeam(user?.email);
      localStorage.setItem("ap_nanonets_default_team", user?.email);
      console.error(error);
    }
  };

  useEffect(() => {
    if (teamId || user?.email || deafultTeamForUser) {
      const pathname = window.location.pathname;
      if (!window.location.pathname.includes("/vendor/onboarding")) {
        const params = new URLSearchParams(window.location.search);
        if (!teamId || teamId === "null")
          params.set("teamId", deafultTeamForUser);
        else params.set("teamId", teamId);
        history.push({
          pathname: pathname,
          search: "?" + params.toString(),
        });
      }
    }
  }, [teamId, user, deafultTeamForUser, history]);

  useEffect(() => {
    if (user) getAllTeamIds();

    if ((window as any).smartlook && user?.email) {
      (window as any).smartlook("identify", user.email, user);
    }
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (window.location.pathname.includes("/connect/")) {
    return (
      <Suspense fallback={<p> Loading...</p>}>
        <Connects />
      </Suspense>
    );
  }

  return (
    <>
      {window.location.pathname.includes("/vendor/") ? (
        <Suspense fallback={<p> Loading...</p>}>
          <VendorRoutes />
        </Suspense>
      ) : (
        <>
          {!isAuthenticated && (
            <Suspense fallback={<p> Loading...</p>}>
              <Login />
            </Suspense>
          )}
        </>
      )}
      {isAuthenticated && (
        <Layout>
          <ConvertProCTA/>
          <Suspense fallback={<p> Loading...</p>}>
            <Switch>
              <Route path="/team/">{isAuthenticated && <Teams />}</Route>
              <Route path="/vendors/:id">
                {isAuthenticated && <VendorDetailsPage />}
              </Route>
              <Route path="/vendors/">
                {isAuthenticated && <VendorsPage />}
              </Route>
              <Route path="/approvals/">
                {isAuthenticated && <Approvals />}
              </Route>
              <Route path="/dashboard/">
                {isAuthenticated && <Dashboard />}
              </Route>
              <Route path="/analytics/">
                {isAuthenticated && <AnalyticsPage />}
              </Route>
              <Route path="/Settings/">{isAuthenticated && <Settings />}</Route>
              <Route path="/admin/feature-flags/">
                {isAuthenticated && <FeatureFlags />}
              </Route>
              <Route path="/admin/site-admins/">
                {isAuthenticated && <SuperAdmin />}
              </Route>
              <Route path="/admin/">{isAuthenticated && <AdminPanel />}</Route>
              <Route path="/Details/:id">
                {isAuthenticated && <Details />}
              </Route>
              {/* <Route path="/DetailsNew/:id">
                {isAuthenticated && <DetailsNew />}
              </Route> */}
              <Route path="/pay/">{isAuthenticated && <Payments />}</Route>
              <Route path="/preview/:id">
                {isAuthenticated && <Preview />}
              </Route>
              <Route path="/archive/">
                {isAuthenticated && <Home screen={FLOW_SCREEN.ARCHIVE} />}
              </Route>
              <Route path="/trash/">
                {isAuthenticated && <Home screen={FLOW_SCREEN.TRASH} />}
              </Route>
              <Route path="/receipt/">
                {isAuthenticated && <Home screen={FLOW_SCREEN.RECEIPT} />}
              </Route>
              <Route path="/po/">
                {isAuthenticated && <Home screen={FLOW_SCREEN.PO} />}
              </Route>
              <Route path="/seller-settings//">
                {isAuthenticated && <SellerSettings />}
              </Route>
              <Route path="/">
                {isAuthenticated && <Home screen={FLOW_SCREEN.INVOICE} />}
              </Route>
            </Switch>
          </Suspense>
        </Layout>
      )}
    </>
  );
};

export default App;

// Refactored NEW_USER_ROLE with TeamUserRole
export enum TeamUserRole {
  ADMIN = "Admin",
  AP_TEAM_MEMBER = "AP Team Member",
  AUDITOR = "Auditor",
  BUSINESS_USER = "Business User",
  CONTROLLER = "Controller",
  EMPLOYEE = "Employee",
  OWNER = "Owner",
  VIEW_ONLY_APPROVER = "View-Only Approver",
  COMPANY_ADMIN = "Company Admin",
}

export type TeamMember = {
  role: TeamUserRole;
  status: "active" | "pending";
  team_member: string;
};

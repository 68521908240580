import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { useNotification } from "../../ui/utils/notification/notification2";
import { useAuth0 } from "../react-auth0-spa";
import { chainError, useDelete, useGet, usePatch, usePost } from "../util";

const PAYMENT_BASE_URL = `payments`;
const ACH_PAYMENT_PROVIDER = "dwolla";
const WISE_PAYMENT_PROVIDER = "wise";

export const useACHMetadata = (teamId: string) => {
  const { getTokenSilently } = useAuth0();
  return useQuery(["achMetadata", teamId], async () => {
    const token: string = await getTokenSilently();
    const url = `/api/v1/payments/metadata/${ACH_PAYMENT_PROVIDER}?teamid=${teamId}`;

    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.ok) {
      return res.json();
    }

    const errorMessage = await res.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useWiseMetadata = (teamId: string) => {
  const get = useGet();
  return useQuery(
    ["wiseMetadata", teamId],
    async () => {
      return get(
        `${PAYMENT_BASE_URL}/metadata/${WISE_PAYMENT_PROVIDER}?teamid=${teamId}`
      );
    },
    {
      retry: 1,
    }
  );
};

export const useWiseBalance = (teamId: string) => {
  const get = useGet();
  return useQuery(["wisebalance", teamId], async () => {
    return get(
      `${PAYMENT_BASE_URL}/balance/${WISE_PAYMENT_PROVIDER}?teamid=${teamId}`
    );
  });
};

export const usePatchWiseMetadata = (teamId: string) => {
  const patch = usePatch();
  const notification = useNotification();
  return useMutation<any, Error, any>(
    async (request) => {
      return patch(`wise/metadata/?teamid=${teamId}`, request);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["wiseMetadata", teamId]);
      },
      onError: (error: Error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useDeleteWiseMetadata = (teamId: string) => {
  const deleteWiseMetadata = useDelete();
  const notification = useNotification();
  return useMutation<any, Error, void>(
    async () => {
      return deleteWiseMetadata(
        `${PAYMENT_BASE_URL}/metadata/${WISE_PAYMENT_PROVIDER}?teamid=${teamId}`
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Deleted");
        queryClient.invalidateQueries(["wiseMetadata", teamId]);
      },
      onError: (error: Error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useCreateACHCustomer = (teamId: string) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();

  return useMutation<any, Error, { customerDetails: any }, unknown>(
    async ({ customerDetails }) => {
      const token = await getTokenSilently();
      const url = `/api/v1/payments/sender/${ACH_PAYMENT_PROVIDER}?teamid=${teamId}`;

      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(customerDetails),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (resp.ok) {
        return resp.json();
      }

      const errorMessage = await resp.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onSuccess: (data) => {
        queryClient.setQueriesData(["customerDetails", teamId], () => {
          return data;
        });
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useCreateACHOwner = (teamId: string) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();

  return useMutation<unknown, Error, { ownerDetails: any }, unknown>(
    async ({ ownerDetails }) => {
      const token = await getTokenSilently();
      const url = `/api/v1/payments/ownership/${ACH_PAYMENT_PROVIDER}?teamid=${teamId}`;

      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(ownerDetails),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (resp.ok) {
        return resp.json();
      }

      const errorMessage = await resp.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["achOnboardingStatus", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useCertifyCustomer = (teamId: string) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();

  return useMutation<unknown, Error, unknown, unknown>(
    async () => {
      const token = await getTokenSilently();
      const url = `/api/v1/payments/certify/${ACH_PAYMENT_PROVIDER}?teamid=${teamId}`;

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (resp.ok) {
        return resp.json();
      }

      const errorMessage = await resp.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["achOnboardingStatus", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useACHOnboardingStatus = (teamId: string) => {
  const { getTokenSilently } = useAuth0();
  return useQuery(["achOnboardingStatus", teamId], async () => {
    const token: string = await getTokenSilently();
    const url = `/api/v1/payments/onboarding/status/${ACH_PAYMENT_PROVIDER}?teamid=${teamId}`;

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (resp.ok) {
      return resp.json();
    }

    const errorMessage = await resp.text();
    return Promise.reject(new Error(errorMessage));
  });
};

export const useUploadDocs = (teamId: string) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();

  return useMutation<
    unknown,
    Error,
    {
      uploadedFile: File;
      entity: "business" | "beneficial-owners";
      docType: string;
      beneficialOwnerId?: number;
    },
    unknown
  >(
    async ({ uploadedFile, entity, docType, beneficialOwnerId }) => {
      const token = await getTokenSilently();
      const url = `/api/v1/docs/${ACH_PAYMENT_PROVIDER}?teamid=${teamId}`;

      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("entity", entity);
      formData.append("docType", docType);

      if (beneficialOwnerId) {
        formData.append("beneficialOwnerId", beneficialOwnerId.toString());
      }

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (resp.ok) {
        return resp.json();
      }

      const errorMessage = await resp.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onError: (error) => {
        notification.error(error.message);
      },
      onSuccess: () => {
        // bring the new data
        // queryClient.invalidateQueries(["achOnboardingStatus", teamId]);
      },
    }
  );
};

export const useDeleteBeneficialOwners = (teamId) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();

  return useMutation<unknown, Error, { beneficialOwnerId: string }, unknown>(
    async ({ beneficialOwnerId }) => {
      const token = await getTokenSilently();
      const url = `/api/v1/ownership/${ACH_PAYMENT_PROVIDER}/${beneficialOwnerId}?teamid=${teamId}`;

      const resp = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (resp.ok) {
        return resp.json();
      }

      const errorMessage = await resp.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onError: (err) => {
        notification.error(err.message);
      },
      onSuccess: () => {
        // bring the new data
        queryClient.invalidateQueries(["achOnboardingStatus", teamId]);
      },
    }
  );
};

export const useACHTransfer = (teamId) => {
  const { getTokenSilently } = useAuth0();
  const notification = useNotification();

  return useMutation<
    unknown,
    Error,
    { invoiceId: string; selectedAccount?: string },
    unknown
  >(
    async (reqBody) => {
      const token = await getTokenSilently();
      const url = `/api/v1/payments/transfer/${ACH_PAYMENT_PROVIDER}?teamid=${teamId}`;

      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (resp.ok) {
        return resp.json();
      }

      const errorMessage = await resp.text();
      return Promise.reject(new Error(errorMessage));
    },
    {
      onSuccess: (data) => {
        notification.success("Paid successfully");
        queryClient.invalidateQueries(["invoices", teamId]);
        queryClient.invalidateQueries(["spends"]);
      },
      onError: (err) => {
        notification.error(err.message);
      },
    }
  );
};

export const useCreateWiseRecipient = (teamId: string) => {
  const post = usePost();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ request, vendorId }) =>
      post(
        `${PAYMENT_BASE_URL}/recipient/${WISE_PAYMENT_PROVIDER}/${vendorId}?teamid=${teamId}`,
        request
      )
  );
};

export const useCreateWisePaymentSchedule = (teamId: string) => {
  const post = usePost();
  const notification = useNotification();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ request, invoiceId }) =>
      post(
        `${PAYMENT_BASE_URL}/schedule/${WISE_PAYMENT_PROVIDER}/${invoiceId}?teamid=${teamId}`,
        request
      ).catch(chainError("Error while sending message")),
    {
      onSuccess: () => {
        notification.success("Payment scheduled");
      },
      onError: (err) => {
        notification.error(err.message);
      },
    }
  );
};

export const usePatchWisePaymentSchedule = (teamId: string) => {
  const patch = usePatch();
  const notification = useNotification();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ request, invoiceId }) =>
      patch(
        `${PAYMENT_BASE_URL}/schedule/${WISE_PAYMENT_PROVIDER}/${invoiceId}?teamid=${teamId}`,
        request
      ).catch(chainError("Error while sending message")),
    {
      onSuccess: () => {
        notification.success("Payment schedule updated");
      },
      onError: (err) => {
        notification.error(err.message);
      },
    }
  );
};

export const useDeleteWisePaymentSchedule = (teamId: string) => {
  const deleteReq = useDelete();
  const notification = useNotification();
  return useMutation<unknown, Error, unknown, unknown>(
    ({ invoiceId }) =>
      deleteReq(
        `${PAYMENT_BASE_URL}/schedule/${WISE_PAYMENT_PROVIDER}/${invoiceId}?teamid=${teamId}`
      ).catch(chainError("Error while sending message")),
    {
      onSuccess: () => {
        notification.success("Payment schedule deleted");
      },
      onError: (err) => {
        notification.error(err.message);
      },
    }
  );
};

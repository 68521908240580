import { useQuery } from "react-query";
import { chainError, useGet } from "../../util";
import { QbPurchaseOrder } from "../../types/qb";

export const useQbPurchaseOrders = (teamId: string) => {
  const get = useGet();
  return useQuery<QbPurchaseOrder[], Error>(["qbPos", teamId], () => {
    return get(`invoices/qb/pos/?teamid=${teamId}`).catch(
      chainError("Failed to fetch qb puchase orders")
    );
  });
};

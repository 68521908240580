import { useLocation } from "react-router-dom";

const useUrlSearchParamsFn = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  return Object.fromEntries(urlSearchParams);
};

const useUrlSearchParamsMock = () => {
  const urlSearchParams = new URLSearchParams("?teamId=info@nanonets.com");
  return Object.fromEntries(urlSearchParams);
};

export const useUrlSearchParams =
  process.env.MOCK_API === "true"
    ? useUrlSearchParamsMock
    : useUrlSearchParamsFn;

const k = 1000;

export const shortenAmount = (amount: number): string => {
  if (typeof amount !== "number") return "";

  let shortAmount = amount;
  let suffix = "";

  if (amount >= k * k * k * k) {
    suffix = "t";
    shortAmount = amount / (k * k * k * k);
  } else if (amount >= k * k * k) {
    suffix = "b";
    shortAmount = amount / (k * k * k);
  } else if (amount >= k * k) {
    suffix = "m";
    shortAmount = amount / (k * k);
  } else if (amount >= k) {
    suffix = "k";
    shortAmount = amount / k;
  }

  return shortAmount.toFixed(2) + suffix;
};

export const asAmountString = (amount: number) => {
  if (typeof amount !== "number") return "";

  return amount.toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

import React, { createContext, ReactNode, useContext, useState } from "react";
import { useNotification } from "../notification/notification2";
import FilePickerView from "./FilePickerView";

type FilePickerInfo = {
  show: (data: Data, onChange: (files: any[]) => void) => void;
};
const MyContext = createContext<FilePickerInfo>({
  show: () => {},
});

type Data = {
  title: string;
  message: string;
  content?: ReactNode;
};
const DEFAULT_DATA = {
  title: "Upload files",
  message: "Drag and drop your files here",
  content: "",
};

export const FilePickerProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Data>(DEFAULT_DATA);
  const notification = useNotification();

  const [onChange, setOnChange] =
    useState<null | ((files: any[]) => void)>(null);

  const show = (d, onChange) => {
    setData(d || DEFAULT_DATA);
    setOnChange(() => onChange);
    setOpen(true);
  };

  const reset = () => {
    setOpen(false);
    setData(DEFAULT_DATA);
    onChange && onChange([]);
    setOnChange(null);
  };

  const onDone = (files: any[]) => {
    setOpen(false);
    setData(DEFAULT_DATA);
    onChange && onChange(files);
    setOnChange(null);
  };

  return (
    <MyContext.Provider value={{ show }}>
      {children}
      <FilePickerView
        options={data}
        onUpload={onDone}
        isDialogOpen={open}
        closeDialog={reset}
        showWarning={notification.warning}
      />
    </MyContext.Provider>
  );
};

export const useFilePicker = () => {
  const info = useContext(MyContext);
  return (data?: Data) => {
    return new Promise<File[]>((resolve, reject) => {
      info.show(data, (files: File[]) => {
        if (files?.length > 0) {
          resolve(files);
        }
        reject(files);
      });
    });
  };
};

import React, { createContext, useContext, useEffect, useState } from "react";
import { FCC } from "../../../lib/types/common/react";

export enum StorageType {
  SESSION,
  LOCAL,
}

const NANO_UI_CONFIG = "NanoUiConfig";

type Json = string | number | boolean | null | { [key: string]: Json } | Json[];
type ConfigType = Record<string, Json>;

const MyContext = createContext<{
  config: ConfigType;
  sessionConfig: ConfigType;
  setConfig?: (config: ConfigType) => void;
  setSessionConfig?: (config: ConfigType) => void;
}>({ config: {}, sessionConfig: {} });

export const UiConfigProvider: FCC = ({ children }) => {
  const [config, setConfig] = useState<ConfigType>({});
  const [sessionConfig, setSessionConfig] = useState<ConfigType>({});

  useEffect(() => {
    // On first load, fetch from storages
    const data = localStorage.getItem(NANO_UI_CONFIG);
    if (data) {
      setConfig(JSON.parse(data));
    }
    const sessionData = sessionStorage.getItem(NANO_UI_CONFIG);
    if (sessionData) {
      setSessionConfig(JSON.parse(sessionData));
    }
  }, []);

  useEffect(() => {
    // After every update, save to localStorage
    localStorage.setItem(NANO_UI_CONFIG, JSON.stringify(config));
  }, [config]);

  useEffect(() => {
    // After every update, save to sessionStorage
    sessionStorage.setItem(NANO_UI_CONFIG, JSON.stringify(sessionConfig));
  }, [sessionConfig]);

  return (
    <MyContext.Provider
      value={{ config, sessionConfig, setConfig, setSessionConfig }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useUiConfig = <T extends Json>(
  key: string,
  storageType?: StorageType
): [T, (value: T) => void] => {
  const { config, sessionConfig, setConfig, setSessionConfig } =
    useContext(MyContext);

  const update = (value: Json) => {
    if (storageType === StorageType.SESSION) {
      setSessionConfig({ ...sessionConfig, [key]: value });
    } else {
      setConfig({ ...config, [key]: value });
    }
  };

  const storedValue =
    storageType === StorageType.SESSION ? sessionConfig[key] : config[key];

  return [storedValue as T, update];
};

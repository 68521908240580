import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { FC } from "react";
import api from "../../../api/api";
import { Category } from "../../../api/types/category";
import { Vendor } from "../../../api/types/vendor";
import { CurrencyMap } from "../../../lib/constants/currency";
import MoreMenu from "../../../ui/components/Menus/MoreMenu";
import { useTeamId } from "../../../utils/team";
import DateFilter, {
  dateFilterToInterval,
  intervalToDateFilter,
} from "../../shared/Filter/DateFilter";
import StringFilter from "../../shared/Filter/StringFilter";
import {
  AnalyticsPageActionType,
  AnalyticsPageDispatch,
  AnalyticsPageState,
} from "../state";
import classes from "./FilterSelection.module.scss";

type FilterData<O = unknown, I = unknown> = {
  key: string;
  name: string;
  type: "string" | "date" | "number";
  options: Array<O>;
  getName: (o: O) => string;
  getId: (o: O) => I;
  selectedValues: I[];
  actionType: AnalyticsPageActionType;
};

const FilterSelection: FC<{
  state: AnalyticsPageState;
  dispatch: AnalyticsPageDispatch;
  baseCurrency: string;
  setBaseCurrency: (c: string) => void;
}> = ({ state, dispatch, baseCurrency, setBaseCurrency }) => {
  const teamId = useTeamId();
  const { data: categories = [] } = api.useCategories(teamId);
  const { data: vendors = [] } = api.useVendors(teamId);

  const categoryFilterData: FilterData = {
    key: "category",
    name: "Category",
    type: "string",
    options: categories,
    getName: (c: Category) => c.name || "",
    getId: (c: Category) => c.id,
    selectedValues: state.filters.category,
    actionType: AnalyticsPageActionType.SET_CATEGORIES,
  };

  const vendorFilterData: FilterData = {
    key: "vendor",
    name: "Vendor",
    type: "string",
    options: vendors,
    getName: (c: Vendor) => c.name || "",
    getId: (c: Vendor) => c.id,
    selectedValues: state.filters.vendor,
    actionType: AnalyticsPageActionType.SET_VENDORS,
  };

  const statusFilterData: FilterData = {
    key: "status",
    name: "Status",
    type: "string",
    options: ["Received", "Paid", "Approved"],
    getName: (c: string) => c,
    getId: (c: string) => c.toLowerCase(),
    selectedValues: state.filters.status,
    actionType: AnalyticsPageActionType.SET_STATUSES,
  };

  const exportStatusFilterData: FilterData = {
    key: "exportStatus",
    name: "Export Status",
    type: "string",
    options: ["Successful", "Failed"],
    getName: (c: string) => c,
    getId: (c: string) => c.toLowerCase(),
    selectedValues: state.filters.exportStatus,
    actionType: AnalyticsPageActionType.SET_EXPORT_STATUS,
  };

  const docTypeFilterData: FilterData = {
    key: "docType",
    name: "Document Type",
    type: "string",
    options: ["Invoice", "Receipt", "PO"],
    getName: (c: string) => c,
    getId: (c: string) => c.toLowerCase(),
    selectedValues: state.filters.docType,
    actionType: AnalyticsPageActionType.SET_DOC_TYPES,
  };

  const allFilterData = [
    docTypeFilterData,
    categoryFilterData,
    vendorFilterData,
    statusFilterData,
    exportStatusFilterData,
  ];
  const invoiceDateFilterData = intervalToDateFilter(state.filters.invoiceDate);
  const exportDateFilterData = intervalToDateFilter(state.filters.exportDate);

  const isEmpty =
    docTypeFilterData.selectedValues.length === 0 &&
    categoryFilterData.selectedValues.length === 0 &&
    vendorFilterData.selectedValues.length === 0 &&
    statusFilterData.selectedValues.length === 0 &&
    exportStatusFilterData.selectedValues.length === 0 &&
    invoiceDateFilterData.type === "" &&
    exportDateFilterData.type === "";

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <div>
          <MoreMenu
            moreButton={
              <Button variant="outlined" color="inherit">
                Currency: {baseCurrency}
              </Button>
            }
            items={Object.keys(CurrencyMap).map((text) => ({
              text,
              onClick: () => setBaseCurrency(text),
            }))}
          />
        </div>
        {allFilterData.map(
          ({
            key,
            name,
            type,
            options,
            getName,
            getId,
            selectedValues,
            actionType,
          }) => {
            if (type === "string") {
              const selection = new Set(selectedValues);
              const selectedOptions = options.filter((o) =>
                selection.has(getId(o))
              );

              return (
                <StringFilter
                  key={key}
                  filterOption={{ key, name, type }}
                  allFilterValues={options.map(getName)}
                  selectedFilterValues={selectedOptions.map(getName)}
                  onFilterChange={(key, values) => {
                    const newSelection = new Set(values);
                    const selectedOptions = options.filter((o) =>
                      newSelection.has(getName(o))
                    );
                    dispatch({
                      type: actionType,
                      [key]: selectedOptions.map(getId),
                    });
                  }}
                />
              );
            }
            return null;
          }
        )}
        <DateFilter
          key="invoiceDate"
          filterOption={{
            name: "Date",
            key: "invoiceDate",
          }}
          dateFilter={invoiceDateFilterData}
          onFilterChange={(key, filter) => {
            dispatch({
              type: AnalyticsPageActionType.SET_INVOICE_DATE,
              invoiceDate: dateFilterToInterval(filter),
            });
          }}
        />
        <DateFilter
          key="exportDate"
          filterOption={{
            name: "Export Date",
            key: "exportDate",
          }}
          dateFilter={exportDateFilterData}
          onFilterChange={(key, filter) => {
            dispatch({
              type: AnalyticsPageActionType.SET_EXPORT_DATE,
              exportDate: dateFilterToInterval(filter),
            });
          }}
        />
        {/* <DateFilter
          key="dueDate"
          filterOption={{
            name: "Due Date",
            key: "dueDate",
          }}
          dateFilter={intervalToDateFilter(state.filters.dueDate)}
          onFilterChange={(key, filter) => {
            dispatch({
              type: AnalyticsPageActionType.SET_DUE_DATE,
              dueDate: dateFilterToInterval(filter),
            });
          }}
        /> */}
      </div>
      {!isEmpty && (
        <div className={classes.clear}>
          <Button
            startIcon={<Close />}
            color="inherit"
            size="small"
            onClick={() => {
              dispatch({
                type: AnalyticsPageActionType.CLEAR_FILTERS,
              });
            }}
            variant="outlined"
          >
            Clear Filters
          </Button>
        </div>
      )}
    </div>
  );
};

export default FilterSelection;
